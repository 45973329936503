import React from "react";
import { Typography, Box } from "@mui/material";

// Componente principal que usa `CabeceraVertical`
const TypographyColumns = ({ data }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      gap="15px"
    >
      {data.map((item, index) => (
        <Typography
          key={index}
          variant={item.variant}
          sx={{
            width: item.maxWidth,
            color: item.color || "#ECEAEF", // Color predeterminado
            fontSize: item.fontSize,
            textTransform: item.textTransform,
            fontWeight: item.fontWeight,
            lineHeight: item.lineHeight,
          }}
        >
          {item.text}
        </Typography>
      ))}
    </Box>
  );
};

export default TypographyColumns;
