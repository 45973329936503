import React from "react";
import { Card, Box, Typography, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import StatusCard from "../componets/statusCard";
import { Doughnut } from "react-chartjs-2";

import DonutSmallTwoToneIcon from "@mui/icons-material/DonutSmallTwoTone";

const data = {
  labels: ["Aprobados", "En espera"],
  datasets: [
    {
      data: [76, 24],
      backgroundColor: ["#2bd374", "#0074D9"],
      borderWidth: 0,
    },
  ],
};

const options = {
  cutout: "70%",
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

const ChartContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "250px",
  height: "190px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",   
  marginBottom: theme.spacing(1),
}));

const CenteredNumber = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",  
  transform: "translate(-50%, -50%)",  
  textAlign: "center",
}));

const ConversionRateCard = () => {
  return (
    <Box>
      <Card sx={{ backgroundColor: "#D9D9D9", borderRadius: "12px",
          padding: 2, maxWidth: "330px", display: "flex", justifyContent: "center" }}>
        <Container>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 2 }}>
            <DonutSmallTwoToneIcon sx={{ mr: 1 }} /> Tasa de Conversión
          </Typography>

          <Box sx={{ mb: 2 }}>
            <StatusCard />
          </Box>

          <ChartContainer>
            <Doughnut data={data} options={options} />
            <CenteredNumber>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", fontSize: "24px" }}
              >
                76%
              </Typography>
            </CenteredNumber>
          </ChartContainer>

          <Box display="flex" justifyContent="space-around">
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  width: "16px",
                  height: "16px",
                  backgroundColor: "#2bd374",
                  borderRadius: "5px",
                  mr: 1,
                }}
              ></Box>
              <Typography variant="body2">Aprobados</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  width: "16px",
                  height: "16px",
                  backgroundColor: "#0074D9",
                  borderRadius: "5px",
                  mr: 1,
                }}
              ></Box>
              <Typography variant="body2">En espera</Typography>
            </Box>
          </Box>
        </Container>
      </Card>
    </Box>
  );
};

export default ConversionRateCard;
