import React from "react";
import { Box, IconButton } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

const SideMenu = () => {
  return (
    <Box display="flex"  flexDirection="column" alignItems="center"
      sx={{ width: "60px", 
        padding: "20px", }} >
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", width: "60px", padding: "20px",
          borderRadius: "30px", alignItems: "center", gap: "10px", backgroundColor: "#DFDFDF" }}>
        <IconButton sx={{ backgroundColor: "#ffffff", color: "#000000" }}>
          <GridViewIcon />
        </IconButton>
        <IconButton sx={{ backgroundColor: "#ffffff", color: "#000000" }}>
          <HomeOutlinedIcon />
        </IconButton>
        <IconButton sx={{ backgroundColor: "#ffffff", color: "#000000" }}>
          <WorkOutlineOutlinedIcon />
        </IconButton>
        <IconButton sx={{ backgroundColor: "#ffffff", color: "#000000" }}>
          <ListAltIcon />
        </IconButton>
        <IconButton sx={{ backgroundColor: "#ffffff", color: "#000000" }}>
          <InfoOutlinedIcon />
        </IconButton>
      </Box>

      <Box sx={{ marginTop:"50px",  display: "flex", flexDirection: "column", width: "60px", padding: "8px",
          borderRadius: "30px", alignItems: "center",  backgroundColor: "#DFDFDF"  }}>
        <IconButton sx={{  color: "#000000" }}>
          <SettingsOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SideMenu;
