import React from 'react';
import DiseñoWeb from '../../../../internalPages/diseñoWeb/pages.jsx';

const ServicioDesarrolloSoftware = () => {
  // Información general del servicio
  const title = "DESARROLLO DE SOFTWARE A MEDIDA";
  const subtitle = "DETALLES DEL SERVICIO";

  // Descripción del servicio
  const introParagraph = `En HitPoly, entendemos que cada empresa es única, por lo que transformamos tus necesidades empresariales en soluciones tecnológicas personalizadas que destacan en el mundo digital. Nuestro equipo de desarrolladores se especializa en crear software a medida que no solo son visualmente atractivos, sino que también mejoran significativamente la funcionalidad y experiencia de uso. Con este servicio, nos enfocamos en empresas que buscan una presencia digital sólida, permitiendo una interacción significativamente mejorada con sus clientes mientras optimizan sus procesos internos.`;

  // Proceso de desarrollo
  const sectionsData = [
    {
      title: "Consulta Inicial y Análisis de Requisitos",
      description: `Comenzamos con una reunión detallada donde analizamos tus necesidades y expectativas para asegurarnos de estar alineados. Esta etapa también incluye un análisis profundo del mercado y la competencia, lo que nos permite identificar oportunidades clave y definir junto a ti las funcionalidades específicas que el software debe tener.`,
      imageSrc: 'images/DesarrolloReunionInicial.jpg',
      imageAlt: 'Consulta Inicial y Análisis de Requisitos',
    },
    {
      title: "Diseño UX/UI",
      description: `Nuestros diseñadores transforman las ideas en wireframes y prototipos interactivos. Creamos interfaces atractivas y fáciles de usar, que no solo refuerzan la identidad de tu marca, sino que también aseguran que cada interacción con tu software sea intuitiva y agradable.`,
      imageSrc: 'images/DesarrolloUxUi.jpg',
      imageAlt: 'Diseño UX/UI',
    },
    {
      title: "Desarrollo",
      description: `Nuestro equipo de desarrollo se encarga de la programación utilizando las tecnologías más avanzadas y metodologías ágiles. Integramos todas las funcionalidades y herramientas necesarias para asegurar que el software no solo funcione de manera óptima, sino que sea un verdadero motor de eficiencia para tu empresa.`,
      imageSrc: 'images/DesarrolloDeSoftware.jpg',
      imageAlt: 'Desarrollo',
    },
    {
      title: "Implementación y Lanzamiento",
      description: `Una vez finalizado el desarrollo, implementamos el software en las plataformas adecuadas y monitoreamos su rendimiento en tiempo real. Nos aseguramos de que todo funcione perfectamente y resolvemos cualquier problema que pueda surgir tras el lanzamiento.`,
      imageSrc: 'images/DesarrolloPruebas.jpg',
      imageAlt: 'Implementación y Lanzamiento',
    },
    {
      title: "Mantenimiento y Actualizaciones",
      description: `El desarrollo no termina con el lanzamiento. Te acompañamos con un soporte continuo, asegurando que el software se mantenga actualizado y funcione sin inconvenientes, mientras seguimos mejorando sus funcionalidades a medida que tu negocio crece.`,
      imageSrc: 'images/DesarrolloMantenimiento.jpg',
      imageAlt: 'Mantenimiento y Actualizaciones',
    },
  ];

  const handleCtaAction = () => {
    window.location.href = "https://meetings.hubspot.com/hitpoly"; // Reemplaza con tu URL
  };

  return (
    <DiseñoWeb 
      title={title}
      subtitle={subtitle}
      sections={sectionsData} // Asegúrate de pasar 'sections' en lugar de 'sectionsData'
      introParagraph={introParagraph}
      outroParagraph={(
        <>
          En HitPoly, entendemos que cada empresa es única y que las soluciones estándar no siempre son suficientes para impulsar un verdadero cambio. Por eso, ofrecemos un servicio de desarrollo de software a medida que se adapta perfectamente a tus necesidades específicas, ayudándote a destacar en un entorno digital altamente competitivo.
          <br /><br />
          Nuestro equipo de expertos no solo crea aplicaciones visualmente atractivas, sino que también se enfoca en optimizar cada funcionalidad para mejorar la eficiencia de tu empresa. Imagina contar con una herramienta personalizada que no solo facilita tu día a día, sino que también potencia la experiencia de tus clientes, permitiéndote interactuar de manera más efectiva con ellos.
          <br /><br />
          Cada solución que desarrollamos está diseñada para integrar las tecnologías más avanzadas, asegurando que tu empresa esté preparada para los desafíos digitales del futuro. Nuestro enfoque se basa en comprender a fondo tus necesidades y traducirlas en soluciones tecnológicas innovadoras.
          <br /><br />
          El software a medida no solo mejora la eficiencia operativa, sino que también impulsa la innovación dentro de tu empresa, permitiéndote implementar nuevas ideas de manera rápida y efectiva. Esto te otorga una ventaja competitiva que te ayudará a diferenciarte en tu sector.
          <br /><br />
          El mundo digital avanza a un ritmo acelerado, y tu negocio no puede quedarse atrás. Un software desarrollado a la medida de tus necesidades es la clave para mejorar tus procesos internos, aumentar la productividad y ofrecer un valor superior a tu audiencia.
          <br /><br />
          En HitPoly, nos comprometemos a estar contigo en cada paso del proceso, desde la conceptualización hasta el lanzamiento y más allá. Nuestro equipo te proporciona un soporte continuo para asegurar que tu software siga siendo una herramienta potente y actualizada a lo largo del tiempo.
          <br /><br />
          No dejes pasar la oportunidad de llevar tu empresa al siguiente nivel. Haz hoy tu primera consulta con nosotros y descubre cómo nuestra experiencia y soluciones personalizadas pueden marcar la diferencia para tu marca.
          <br /><br />
          ¡El futuro digital está a un clic de distancia! Contáctanos y juntos transformaremos tu visión en realidad.
        </>
      )}
      
        ctaText="¡Haz tu primera consulta con nosotros!"
                        
      ctaAction={handleCtaAction} 
    />
  );
}

export default ServicioDesarrolloSoftware;
