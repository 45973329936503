import React from 'react';
import { Container, ContentContainer, Item, PrincipalContainer, Wrapper } from '../components/layout/layout';
import FormRegister from './components/Form';
import imagenRegister from '../../assets/prueba.png'; 
import { Box, Typography } from '@mui/material';

const Register = () => {
  return (
    <Wrapper bg={'rgb(239,239,239)'} ac>
      <PrincipalContainer>
        <ContentContainer>
          <Container 
            alignItems="center" 
            justifyContent={"space-around"} 
            gap={2}
            sx={{
              flexDirection: { xs: 'column', md: 'row' }
            }}
          >
            <Item xs={12} md={5}>
              <FormRegister />
            </Item>
            <Item 
              xs={12} 
              md={6} 
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              <img 
                src={imagenRegister} 
                alt='Registro Ilustración' 
                style={{ objectFit: "fill", width: "100%", height: "100%" }} 
              />
            </Item>
          </Container>
        </ContentContainer>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '50%', mt: 3 }}>
          <Typography variant="caption">Hitpoly © 2024</Typography>
          <Typography variant="caption">support@hitpoly.com</Typography>
        </Box>
      </PrincipalContainer>
    </Wrapper>
  );
}

export default Register;
