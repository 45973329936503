import React, { useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import CustomCard from "../../components/cajaDePortafolio/cajaDePortaolio"; // Ensure the path is correct for your project
import Tigo from "../../../assets/tigo-logo.png";
import Jacmont from "../../../assets/jacmont-logo.png";
import Cietep from "../../../assets/cietep.png";
import Registration from "../../../assets/hk-registration.png";
import Hihome from "../../../assets/logo-hihome.webp";
import Arfasa from "../../../assets/arfasa.png";
import Futura from "../../../assets/futura-home.png";
import Mark from "../../../assets/mark-and-vetty.png";
import Oficitca from "../../../assets/oficitca.png";
import Laboratiriosfarma from "../../../assets/laboratorios-farma.png";
import Segurosamc from "../../../assets/AmcLogo.png";
import Acora from "../../../assets/acora.png";
import Cbdvip from "../../../assets/cbdvip.png";

const servicesData = [
  // Services data
  {
    title: "Tigo Costa Rica",
    description:
      "En Tigo, construimos autopistas digitales que conectan a las personas, mejoran vidas y desarrollan nuestras comunidades. Impulsamos el valor de la conectividad digital para acercar personas, comunidades y empresas.",
    category: "Community Manager",
    image: "/images/bannerTigo.png",
    logo: Tigo,
    circleBackground: "#06377c",
  },
  {
    title: "Clinica Jacmont",
    description:
      "Somos pioneros en el uso de la Medicina Regenerativa y la Ozonoterapia Médica, brindando soluciones innovadoras para el tratamiento de diversas patologías. Nuestro enfoque se centra en mejorar la calidad de vida de los pacientes.",
    category: "Community Manager",
    image: "/images/bannerClinicaJacmont.jpg",
    circleBackground: "#1ba2c9",
    logo: Jacmont,
  },
  {
    title: "7P Marketing Corp",
    description:
      "Creemos que los negocios son una vía para el auto-descubrimiento y para generar un impacto positivo. Nuestra misión es ayudar a las empresas a crecer con estrategias que conecten y aporten valor a sus audiencias.",
    category: "Community Manager",
    image: "/images/bannerCietePMarkting.jpg",
    logo: Cietep,
    circleBackground: "#2D1638",
  },
  {
    title: "HK-Registration",
    description:
      "Brindamos servicios de asesoramiento jurídico y contable con el objetivo de asegurar la conformidad legal y optimizar la gestión empresarial. Guiamos a nuestros clientes a que impulsen su éxito y tranquilidad.",
    category: "Community Manager",
    image: "/images/bannerHkRegistration.jpg",
    logo: Registration,
    circleBackground: "#ECEAEF",
  },
  {
    title: "Hi Home RD",
    description:
      "Hi Home RD es una empresa líder en consultoría inmobiliaria y financiera en la República Dominicana. Ofrecemos altos retornos de inversión y ganancias de capital para nuestros clientes.",
    category: "Community Manager",
    image: Hihome,
    logo: Hihome,
    image: "/images/bannerHiHommeRd.jpg",
    circleBackground: "#ECEAEF",
  },
  {
    title: "Arfasa Abogados",
    description:
      "Arfasa Abogados es una firma especializada en derecho familiar, con soluciones eficientes y confiables. Nuestro enfoque es brindar tranquilidad a nuestros clientes mientras gestionamos sus casos legales.",
    category: "Community Manager",
    image: "/images/bannerArfasaAbogados.jpeg",
    logo: Arfasa,
    circleBackground: "#1b2343",
  },
  {
    title: "Futura Home",
    description:
      "En Futura Home, transformamos tu hogar en un espacio inteligente y eficiente. Ofrecemos soluciones innovadoras que automatizan funciones estratégicas para mejorar tu comodidad y optimizar tu estilo de vida.",
    category: "Desarrollo Web & App",
    image: "/images/bannerFuturaHomme.png",
    logo: Futura,
  },
  {
    title: "Mark and Vetty",
    description:
      "Potenciamos tu empresa con una solución digital integral. Ofrecemos servicios que incluyen branding, desarrollo de páginas web, gestión de redes sociales, producción audiovisual y creatividad comercial.",
    category: "Desarrollo Web & App",
    image: "/images/markBetty.jpg",
    logo: Mark,
    circleBackground: "#67ffcc",
  },
  {
    title: "Sistemas Modulares Oficit",
    description:
      "En Ofisitca, somos líderes en ofrecer soluciones integrales para tu oficina. Proporcionamos mobiliario de alta calidad, sistemas de almacenaje eficientes, equipos para carga pesada y un diseño de proyectos personalizado.",
    category: "Desarrollo Web & App",
    image: "/images/bannerofisitca.jpeg",
    logo: Oficitca,
  },
  {
    title: "Laboratorios farma",
    description:
      "Somos el primer laboratorio en Venezuela con certificación INVIMA y contamos con más de 80 años de experiencia. Ofrecemos productos de la más alta calidad para cuidar de tu salud con la mayor cercanía.",
    category: "Desarrollo Web & App",
    image: "/images/bannerLaboratoriosFarma.jpeg",
    logo: Laboratiriosfarma,
    circleBackground: "#007A53",
  },
  {
    title: "7P Marketing Corp",
    description:
      "Creemos que los negocios son una vía para el auto-descubrimiento y para generar un impacto positivo. Nuestra misión es ayudar a las empresas a crecer con estrategias que conecten y aporten valor a sus audiencias.",
    category: "Publicidad Digital",
    image: "/images/bannerCietePMarkting.jpg",
    logo: Cietep,
    circleBackground: "#2D1638",
  },
  {
    title: "HK-Registration",
    description:
      "Brindamos servicios de asesoramiento jurídico y contable con el objetivo de asegurar la conformidad legal y optimizar la gestión empresarial. Guiamos a nuestros clientes a que impulsen su éxito y tranquilidad.",
    category: "Publicidad Digital",
    image: "/images/bannerHkRegistration.jpg",
    logo: Registration,
    circleBackground: "#ECEAEF",
  },
  {
    title: "Seguros AMC",
    description:
      "Protege a tu familia con una póliza que te respalda en emergencias médicas. Con cobertura para accidentes leves y graves, asegúrate de tener la tranquilidad que necesitas. Te ayudamos de forma rápida y eficaz.",
    category: "Publicidad Digital",
    image: "/images/bannerAmc.png",
    circleBackground: "#ECEAEF",
    logo: Segurosamc,
  },
  {
    title: "Casas inteligentes Acora",
    description:
      "Transformamos tu hogar con soluciones de domótica avanzadas para un estilo de vida moderno y eficiente. Automatizamos tu entorno para maximizar comodidad y seguridad.",
    category: "Publicidad Digital",
    image: "/images/bannerAcora.jpeg",
    logo: Acora,
  },
  {
    title: "CBD VIP",
    description:
      "Mejoramos tu calidad de vida ofreciendo apoyo y seguimiento integral para gestionar problemas de salud. Nuestra misión es proporcionarte soluciones efectivas y personalizadas con el mejor respaldo.",
    category: "Publicidad Digital",
    image: "/images/bannerCbdVip.jpg",
    logo: Cbdvip,
  },
  {
    title: "LISANDRO Y ABOGADOS - PERÚ",
    description:
      "Logramos un ROI de 4.2% para esta firma legal en Lima, especializándonos en campañas de Google Ads, Facebook Ads, y gestión de redes sociales para diversas áreas del derecho.",
    category: "Diseño UI/UX",
    image: "/images/bannerLisandroYAbogados.jpg",
    logo: "/images/klaviyo.png",
  },
];

const categories = [
  "Community Manager",
  "Desarrollo Web & App",
  "Publicidad Digital",
  "Diseño UI/UX",
];

const ServicesGrid = () => {
  const [selectedCategory, setSelectedCategory] = useState("Community Manager");

  const filteredServices = servicesData.filter(
    (service) => service.category === selectedCategory
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: { xs: "20px", sm: "10px 60px" },
        marginBottom: "50px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 4,
          flexWrap: "wrap",
        }}
      >
        {categories.map((category) => (
          <Button
            key={category}
            onClick={() => setSelectedCategory(category)}
            variant={selectedCategory === category ? "contained" : "outlined"}
            sx={{
              color: "#ffffff",
              borderColor: "#8a2be2",
              backgroundColor:
                selectedCategory === category ? "#373637" : "transparent",
              borderRadius: "10px",
              mx: 1,
              mb: 2,
              "&:hover": {
                backgroundColor: "#373637",
                color: "#fff",
              },
            }}
          >
            {category}
          </Button>
        ))}
      </Box>

      <Grid
        container
        spacing={2}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {filteredServices.map((service, index) => (
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={index}>
            <CustomCard
              title={service.title}
              description={service.description}
              category={service.category}
              image={service.image}
              link="#"
              logo={service.logo}
              circleBackground={service.circleBackground}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ServicesGrid;
