import React from "react";
import { Card, CardMedia, CardContent, Typography, Button } from "@mui/material";
import ArrowOutward from "@mui/icons-material/ArrowOutward";

const CardDeArticulosMasVistos = ({ title, description, image, link }) => {
  return (
    <Card
      sx={{
        borderRadius: "15px",
        transform: "translateY(-10px)",
        boxShadow: "0 15px 25px rgba(0, 0, 0, 0.2)",
        background: "#ECEAEF",
        display: "flex",
        flexDirection: "column",
        width: "90%",
        maxWidth: "380px",
        height: "550px",
        cursor: "pointer",
        transition: "transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease, color 0.3s ease",
        "&:hover": {
          background: "#7311bb", // Cambiar el color de fondo al hacer hover
          color: "#ECEAEF", // Cambiar el color de todos los textos al hacer hover
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Sombra sutil
          transition:
            "background 0.3s ease, color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease", // Añadido para suavidad
          "& .text-hover": {
            color: "#ECEAEF", // Cambiar el color de los textos específicos
          },
          "& .button-hover": {
            backgroundColor: "#ffffff", // Cambiar el fondo del botón al hacer hover en la tarjeta
            color: "#6F4CE0", // Cambiar el color del texto del botón al hacer hover en la tarjeta
          },
        },
      }}
    >
      <CardMedia
        component="img"
        height="300px"
        image={image}
        alt={title}
        sx={{ objectFit: "cover", transition: "opacity 0.3s ease", "&:hover": { opacity: 0.9 } }}
      />
      <CardContent sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <Typography
          variant="h6"
          className="text-hover" // Clase para modificar el color del texto
          sx={{
            color: "#B51AD8", // Color inicial del texto del título
            fontWeight: "500",
            marginBottom: "10px",
            fontSize: { xs: "16px", sm: "18px", md: "18px" },
            transition: "color 0.3s ease", // Transición para el color
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          className="text-hover" // Clase para modificar el color del texto
          sx={{
            color: "#37183E", // Color inicial del texto de la descripción
            marginBottom: "20px",
            width: "90%",
            fontSize: { xs: "14px", sm: "16px", md: "14px" },
            transition: "color 0.3s ease", // Transición para el color
          }}
        >
          {description}
        </Typography>
      </CardContent>
      <Button
        component="a"
        href={link}
        target="_blank"
        variant="contained"
        color="primary"
        className="button-hover" // Clase para modificar el color del botón
        sx={{
          backgroundColor: "#7311bb", // Color inicial del botón
          color: "#ffffff", // Color inicial del texto del botón
          marginBottom: "20px",
          marginLeft: "15px",
          width: { xs: "70%", sm: "80%", md: "70%" },
          borderRadius: "20px",
          textTransform: "none",
          fontSize: { xs: "14px", sm: "14px", md: "16px" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "background-color 0.3s ease, color 0.3s ease", // Transiciones
          "&:hover": {
            backgroundColor: "#3E1F7E", // Color del botón al hacer hover en el propio botón
            color: "#ffffff", // Color del texto del botón al hacer hover en el propio botón
          },
        }}
      >
        Leér más
        <ArrowOutward
          sx={{
            fontSize: { xs: "18px", sm: "18px", md: "22px" },
            marginLeft: "10px",
            transform: "rotate(-0deg)",
          }}
        />
      </Button>
    </Card>
  );
};

export default CardDeArticulosMasVistos;
