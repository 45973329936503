import React from "react";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const PageHeader = () => {
  return (
   
      <Box display="flex" justifyContent="space-between"
        alignItems="center"
        sx={{ padding: "54px", margin: "6px" }}>
        <Box>
          <Typography variant="h6" color="textSecondary">
            Dashboard - Web Hitpoly
          </Typography>
          <Typography variant="h4" fontWeight="bold">
            Hola, Juan Carlos
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" gap={2}>
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            sx={{
              backgroundColor: "#EBEBEB",
              color: "black",
              minWidth: "0px",
              borderRadius: "50%",
              padding: "10px",
              "& .MuiButton-startIcon": {
                margin: 0,
              },
            }}
          ></Button>
          <Button
            variant="contained"
            startIcon={<CalendarTodayIcon />}
            sx={{
              backgroundColor: "#DFDFDF",
              color: "black",
              borderRadius: "20px",
              minWidth: "40px",
            }}
          >
            Agosto 2024
          </Button>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            sx={{
              backgroundColor: "#61f1a4",
              color: "black",
              borderRadius: "20px",
            }}
          >
            Descargar Reporte
          </Button>
        </Box>
      </Box>
  
  );
};

export default PageHeader;
