import React from "react";
import {
  Box,
  Typography,
  Card,
  List,
  ListItem,
  ListItemText,
  Link,
  IconButton,
  Grid2,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";

const iconStyles = [
  {
    icon: <InstagramIcon />,
    backgroundColor: "#B51AD8",
    link: "https://www.instagram.com/hitpoly/",
  },
  {
    icon: <FacebookIcon />,
    backgroundColor: "#B51AD8",
    link: "https://www.facebook.com/Hitpoly",
  },
  {
    icon: <LinkedInIcon />,
    backgroundColor: "#B51AD8",
    link: "https://pe.linkedin.com/company/hitpoly",
  },
  {
    icon: <WhatsAppIcon />,
    backgroundColor: "#B51AD8",
    link: "https://wa.me/51933961352",
  },
  {
    icon: <YouTubeIcon />,
    backgroundColor: "#B51AD8",
    link: "https://www.youtube.com/@hitpolyagenciademarketingd5365",
  },
];

const ContentSection = () => {
  return (
    <Box
      sx={{
        color: "#ffffff", // Texto blanco
        padding: { xs: "20px", sm: "40px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Typography
        variant="body1"
        sx={{
          fontSize: { xs: "1rem", sm: "1.2rem" },
          marginBottom: "20px",
          maxWidth: "80vw",
          textAlign: "justify",
        }}
      >
        Al aplicar cada uno de estos componentes a un problema o proyecto,
        puedes descomponerlo en sus elementos fundamentales y reconfigurarlos de
        maneras novedosas. Ya sea que estés desarrollando un nuevo producto,
        mejorando un proceso empresarial o buscando formas de revitalizar una
        campaña de marketing.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: { xs: "1rem", sm: "1.2rem" },
          marginBottom: "20px",
          maxWidth: "80vw",
          textAlign: "justify",
          marginBottom: "60px",
        }}
      >
        Al aplicar cada uno de estos componentes a un problema o proyecto,
        puedes descomponerlo en sus elementos fundamentales y reconfigurarlos de
        maneras novedosas. Ya sea que estés desarrollando un nuevo producto,
        mejorando un proceso empresarial o buscando formas de revitalizar una
        campaña de marketing.
      </Typography>
      <Card
        sx={{
          width: "100%",
          maxWidth: "900px",
          borderRadius: "12px",
          overflow: "hidden",
          marginBottom: "60px",
        }}
      >
        <img
          src="https://via.placeholder.com/900x400"
          alt="Ejemplo de marketing B2B"
          style={{ width: "100%", height: "auto" }}
        />
      </Card>
      <Card
        sx={{
          width: "80%",
          maxWidth: "900px",
          background: `radial-gradient(circle at bottom right, #7311BB 10%, transparent 30%),
              radial-gradient(circle at top left, #7311BB 0%, transparent 0%)`,
          border: "1px solid #828080",
          borderRadius: "22px",
          padding: "20px",
          marginBottom: "40px",
          position: "relative",
          overflow: "visible",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "-20px",
            left: "20px",
            backgroundColor: "#fff",
            color: "#000",
            padding: "10px 20px",
            borderRadius: "10px",
            fontWeight: "bold",
            zIndex: 1,
          }}
        >
          Índice de Contenidos
        </Box>

        <List sx={{ textAlign: "left", color: "#ffffff" }}>
          <ListItem
            component="a"
            href="https://blog.hubspot.es/marketing/marketing-b2b#que-es"
            target="_blank"
            sx={{ color: "#fff" }}
          >
            <ListItemText href="" primary="1. Qué es el marketing B2B" />
          </ListItem>
          <ListItem
            component="a"
            href="https://blog.hubspot.es/marketing/marketing-b2b#cual"
            target="_blank"
            sx={{ color: "#fff" }}
          >
            <ListItemText primary="2. Cuál es la diferencia entre el marketing B2B y el marketing B2C" />
          </ListItem>
          <ListItem
            component="a"
            href="https://blog.hubspot.es/marketing/marketing-b2b#practicas"
            target="_blank"
            sx={{ color: "#fff" }}
          >
            <ListItemText primary="3. 5 prácticas clave del marketing B2B" />
          </ListItem>
          <ListItem
            component="a"
            href="https://blog.hubspot.es/marketing/marketing-b2b#estrategias"
            target="_blank"
            sx={{ color: "#fff" }}
          >
            <ListItemText primary="4. Las 9 estrategias de marketing B2B más efectivas" />
          </ListItem>
          <ListItem
            component="a"
            href="https://blog.hubspot.es/marketing/marketing-b2b#ejemplos"
            target="_blank"
            sx={{ color: "#fff" }}
          >
            <ListItemText primary="4. 11 ejemplos de marketing B2B " />
          </ListItem>
        </List>
      </Card>

      <Typography
        variant="body1"
        sx={{
          fontSize: { xs: "1rem", sm: "1.2rem" },
          marginBottom: "20px",
          maxWidth: "80vw",
          textAlign: "justify",
        }}
      >
        Al aplicar cada uno de estos componentes a un problema o proyecto,
        puedes descomponerlo en sus elementos fundamentales y reconfigurarlos de
        maneras novedosas. Ya sea que estés desarrollando un nuevo producto,
        mejorando un proceso empresarial o buscando formas de revitalizar una
        campaña de marketing.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: { xs: "1rem", sm: "1.2rem" },
          marginBottom: "40px",
          maxWidth: "80vw",
          textAlign: "justify",
        }}
      >
        Al aplicar cada uno de estos componentes a un problema o proyecto,
        puedes descomponerlo en sus elementos fundamentales y reconfigurarlos de
        maneras novedosas. Ya sea que estés desarrollando un nuevo producto,
        mejorando un proceso empresarial o buscando formas de revitalizar una
        campaña de marketing.
      </Typography>

      <Typography
        variant="body1"
        sx={{
          fontWeight: "bold",
        }}
      >
        ¡NO OLVIDES COMPARTIR ESTE ARTICULO!
      </Typography>

      {/* Iconos Redes Sociales */}

      <Grid2 container spacing={2} justifyContent="center">
        {iconStyles.map(({ icon, backgroundColor, link }, index) => (
          <Link
            key={index}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ display: "inline-block", lineHeight: 0 }}
          >
            <IconButton
              sx={{
                backgroundColor: backgroundColor,
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: backgroundColor,
                  opacity: 0.8,
                },
                width: 36,
                height: 36,
                borderRadius: "8px",
                marginTop: "30px",
              }}
            >
              {icon}
            </IconButton>
          </Link>
        ))}
      </Grid2>
    </Box>
  );
};

export default ContentSection;
