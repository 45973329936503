import React from "react";
import { Grid, Box } from "@mui/material";
import ImagenConFondoDegradado from "../../../../components/imagenes/conMarcoEsquinasDegradadasRedondo/cardFraseInspiraciónal"; // Ajusta la ruta
import YoutubeVideoConGuia from "../../../../components/videos/videoMensaje/videoMensajeUno"; // Ajusta la ruta
import CajaDegradadaConImagen from "../../../../components/imagenes/imagenGraficos/imagenGraficos"; // Ajusta la ruta

const ImagenFraseInspiracion = () => {
  const quote = {
    quoteText:
      '“El éxito es la suma de pequeños esfuerzos repetidos día tras día.“',
    author: "Robert Collier",
  };

  return (
    <Grid container spacing={4} justifyContent="center" alignItems="flex-end">
      {/* Caja con texto */}
      <Grid item xs={12} sm={6} md={3} order={{ xs: 1, sm: 1, md: 0 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ImagenConFondoDegradado
            quoteText={quote.quoteText}
            author={quote.author}
          />
        </Box>
      </Grid>

      {/* Video */}
      <Grid item xs={12} sm={6} md={6} order={{ xs: 0, sm: 2, md: 0 }}>
        <YoutubeVideoConGuia />
      </Grid>

      {/* Caja con imagen */}
      <Grid item xs={12} sm={6} md={3} order={{ xs: 2, sm: 2, md: 0 }}>
        <Box
          sx={{
            flexDirection: "column",
          }}
        >
          <Box sx={{ marginBottom: "10px" }}>
            <CajaDegradadaConImagen imageSrc="/images/derecha.png" />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ImagenFraseInspiracion;
