import React from 'react';
import { Box } from '@mui/material';
import CajaDeBotones from '../../CajasDeBotones/CajaDeBotones';

const botonesData = ["Soy Emprendedor", "Soy Freelancer"];
const enlacesData = ["https://wa.link/jd93nf", "https://wa.link/crk451"];  // Enlaces correspondientes a cada botón
const tituloBotones = ["¡Revisa las Oportunidades Disponibles para tí!"];

const CajaDegradadaConImagen = ({ imageSrc }) => {
  return (
    <Box
      sx={{
        padding: '20px',
        gap: '20px',
        display: "flex",
        flexDirection: 'column',
        background: `
          radial-gradient(circle at top left, #7311BB 1%, transparent 50%),
          radial-gradient(circle at bottom right, #7311BB 10%, transparent 50%)
        `,
        borderRadius: '25px',
      }}
    >
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <img 
          src={imageSrc} 
          alt="Descripción de la imagen" 
          style={{ width: '100%', height: 'auto', borderRadius: '25px' }}
        />
      </Box>
      <CajaDeBotones
        title={tituloBotones}  // Título que se pasará al componente
        buttonTexts={botonesData}  // Array de textos para los botones
        buttonLinks={enlacesData}  // Array de enlaces para los botone
      />
    </Box>
  );
};

export default CajaDegradadaConImagen;
