import React from 'react';
import DiseñoWeb from '../../../../internalPages/diseñoWeb/pages.jsx';

const ServicioEmailMarketing = () => {
  const title = "EMAIL MARKETING";
  const subtitle = "CONEXIÓN PERSONALIZADA Y EFECTIVA CON TUS CLIENTES";

  const introParagraph = `En HitPoly, nuestro servicio de Email Marketing está diseñado para fortalecer la relación con tus clientes y potenciales clientes mediante mensajes personalizados y estrategias que generan conversiones. Aprovechamos herramientas avanzadas para segmentar tu base de datos y ofrecer contenido relevante, logrando un alto impacto en cada envío.`;

  const sectionsData = [
    {
      title: "Estrategia de Email Marketing",
      description: `Desarrollamos una estrategia integral de Email Marketing que se adapta a los objetivos específicos de tu negocio. Creamos campañas automatizadas que envían el mensaje adecuado en el momento preciso, aumentando las probabilidades de conversión y fidelización.`,
      imageSrc: 'images/estrategiasDeEmailMarketing.jpg',
      imageAlt: 'Estrategia de Email Marketing',
    },
    {
      title: "Diseño y Redacción de Emails",
      description: `Nuestros expertos diseñan y redactan emails visualmente atractivos y persuasivos que conectan con tu audiencia. Cada email es optimizado para dispositivos móviles y utiliza llamados a la acción claros que impulsan a tus lectores a interactuar con tu contenido.`,
      imageSrc: 'images/DiseñoDeEmails.jpg',
      imageAlt: 'Diseño de Emails',
    },
    {
      title: "Automatización de Campañas",
      description: `Implementamos flujos de trabajo automatizados que se ajustan al comportamiento del usuario, permitiendo enviar mensajes específicos en el momento adecuado. Esto garantiza que cada correo electrónico sea relevante y oportuno para el destinatario.`,
      imageSrc: 'images/AutomatizaciónDeCampañas.jpg',
      imageAlt: 'Automatización de Campañas',
    },
    {
      title: "Análisis y Optimización",
      description: `Monitoreamos el rendimiento de cada campaña, analizando tasas de apertura, clics y conversiones. Con estos datos, optimizamos las estrategias de email marketing para garantizar que cada envío sea más efectivo que el anterior.`,
      imageSrc: 'images/Testing-y-Optimización.jpg',
      imageAlt: 'Análisis y Optimización',
    },
    {
      title: "Beneficios",
      description: `El Email Marketing te permite conectar directamente con tu audiencia de manera personalizada. Aumenta las conversiones, mejora la lealtad del cliente y ofrece un excelente retorno de inversión. Con nuestras soluciones, lograrás campañas que realmente impacten.`,
      imageSrc: 'images/BeneficiosDelEmailMarketing.jpg',
      imageAlt: 'Beneficios del Email Marketing',
    },
  ];

  const handleCtaAction = () => {
    window.location.href = "https://meetings.hubspot.com/hitpoly"; // Reemplaza con tu URL
  };

  return (
    <DiseñoWeb 
      title={title}
      subtitle={subtitle}
      sections={sectionsData}
      introParagraph={introParagraph}
      outroParagraph={(
        <>
          En HitPoly, el Email Marketing no es solo una herramienta para enviar correos, sino una poderosa estrategia para conectar de forma auténtica con tu audiencia. Nos enfocamos en crear contenido relevante y personalizado que inspire a tus clientes a tomar acción, construyendo relaciones duraderas que beneficien tu negocio.
          <br /><br />
          Nuestro equipo de especialistas trabaja contigo para garantizar que cada campaña sea efectiva y genere los resultados esperados. Con nuestra experiencia en Email Marketing, tendrás la confianza de que cada mensaje enviado impulsa tu marca hacia el éxito.
          <br /><br />
          ¡No dejes pasar la oportunidad de llevar tu comunicación al siguiente nivel! Contáctanos hoy y descubre cómo nuestras soluciones personalizadas pueden transformar tu estrategia de marketing.
          <br /><br />
          El éxito en Email Marketing está a solo un clic. ¡Haz tu primera consulta con nosotros y comienza a ver resultados inmediatos!
        </>
      )}
      ctaText="¡Haz tu primera consulta con nosotros!"
      ctaAction={handleCtaAction} 
    />
  );
}

export default ServicioEmailMarketing;
