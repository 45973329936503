import React from 'react';
import { Box, Grid2 } from '@mui/material';
import NavGral from '../../navbarHomeGral/navbarGral';
import Homepage from '../../internalPages/Marketing/components/HomePage';
import ContentSection from '../../internalPages/Marketing/components/ContentSection';
import ContentInfocards from '../../internalPages/Marketing/components/ContentInfoCards';
import Footer from '../../footer/page'

const Marketing = () => {
    return (
        <Box>
        <Grid2>
          <NavGral />
          <Homepage />
          <ContentSection />
          <ContentInfocards />
          <Footer />
        </Grid2>
      </Box>
    )
};

export default Marketing;