import React, { useRef, useState, useEffect } from "react";
import { Box, IconButton, Button, Typography } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { createGlobalStyle } from "styled-components";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

// Estilos globales para animaciones
const GlobalStyle = createGlobalStyle`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-10px);
    }
  }
`;

const VideoInternoConGuia = () => {
  const videoRef = useRef(null);
  const [videoEnded, setVideoEnded] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [showPlayIcon, setShowPlayIcon] = useState(true); // Estado para mostrar el ícono de "Play" inicialmente

  useEffect(() => {
    const video = videoRef.current;

    const handleTimeUpdate = () => {
      if (video) {
        const percent = (video.currentTime / video.duration) * 100;
      }
    };

    if (video) {
      video.addEventListener("timeupdate", handleTimeUpdate);
    }

    return () => {
      if (video) {
        video.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, []);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setShowPlayIcon(false); // Ocultar ícono al reproducir
      } else {
        videoRef.current.pause();
      }
    }
  };

  const handleEnd = () => {
    setVideoEnded(true);
  };

  const handleVideoClick = () => {
    handlePlayPause(); // Reproduce o pausa el video al hacer clic
  };

  const handleRestartVideo = () => {
    setVideoEnded(false);
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // Reiniciar el video
      videoRef.current.play(); // Reproducir el video
      setShowPlayIcon(false); // Ocultar el ícono al reiniciar
    }
  };

  return (
    <>
      <GlobalStyle />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          padding: { xs: "0px", md: "10px" },
          backgroundColor: { xs: "transparent", md: "#f9f9f9" },
          borderRadius: { xs: "0px", md: "25px" },
        }}
      >
        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
            borderRadius: { xs: "0px", md: "25px" },
            width: "100%",
            height: "100%",
          }}
          onMouseEnter={() => setShowControls(true)}
          onMouseLeave={() => setShowControls(false)}
        >
          {!videoEnded ? (
            <>
              <video
                ref={videoRef}
                width="100%"
                height="100%"
                onEnded={handleEnd}
                style={{
                  borderRadius: { xs: "0px", md: "25px" },
                  objectFit: "cover",
                  display: "block",
                }}
                onClick={handleVideoClick} // Agregar el manejador de clics al video
              >
                <source
                  src="/video/creaUnNegocioDigitalDesdeCero.mp4"
                  type="video/mp4"
                />
              </video>

              {/* Control de Play/Pause en el centro */}
              {(showControls || showPlayIcon) && ( // Mostrar el ícono de "Play" inicialmente o cuando se pasa el mouse
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "50%",
                    padding: "10px",
                    opacity: showControls || showPlayIcon ? 1 : 0,
                    transition: "opacity 0.3s ease-in-out", // Transición suave
                  }}
                >
                  <IconButton
                    onClick={handlePlayPause}
                    sx={{ color: "white", fontSize: "48px" }}
                  >
                    {videoRef.current && !videoRef.current.paused ? (
                      <PauseIcon sx={{ fontSize: "48px" }} />
                    ) : (
                      <PlayArrowIcon sx={{ fontSize: "48px" }} />
                    )}
                  </IconButton>
                </Box>
              )}
            </>
          ) : (
            // Caja de texto y icono animado
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column", // Apila elementos verticalmente
                justifyContent: "center",
                alignItems: "center",
                padding: { xs: "30px", md: "60px" },
                backgroundColor: "white",
                borderRadius: { xs: "25px", md: "25px" },
              }}
            >
              <ArrowUpwardIcon
                sx={{
                  animation: "bounce 1.5s infinite",
                  color: "black",
                  fontSize: "50px",
                }}
              />
              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                  animation: "fadeIn 1s",
                  color: "#6F4CE0",
                  fontSize: "30px",
                  marginTop: 2,
                }}
              >
                ¡Crea un negocio digital desde Cero!
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  animation: "fadeIn 1s",
                  color: "#37183E",
                  marginTop: 1,
                  width: "80%",
                }}
              >
                Descubre cómo lanzar tu negocio digital de manera efectiva con
                la experiencia de Hitpoly. Esta guía integral te proporcionará
                las estrategias clave, herramientas digitales y consejos
                prácticos necesarios para construir y hacer crecer tu
                presencia online.
              </Typography>
              <Button
                variant="contained"
                onClick={handleRestartVideo}
                sx={{
                  marginTop: 2,
                  backgroundColor: "#6F4CE0",
                  color: "white",
                  borderRadius: "25px",
                  "&:hover": {
                    backgroundColor: "#5a3ab3",
                  },
                }}
              >
                Volver a ver el video
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default VideoInternoConGuia;
