import React from "react";
import { Box, Card, Container, IconButton, Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { styled } from "@mui/material/styles";
import LanguageTwoToneIcon from "@mui/icons-material/LanguageTwoTone";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LanguageIcon from "@mui/icons-material/Language";

const data = {
  labels: [],
  datasets: [
    {
      data: [20, 30, 40, 10],
      backgroundColor: ["#36A2EB", "#4BC0C0", "#0000FF", "#00FF00"],
      borderWidth: 3,
    },
  ],
};

const options = {
  cutout: "70%",
  plugins: {
    legend: {
      display: false, 
    },
    tooltip: {
      enabled: false,
    },
  },
};

const ChartContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "250px",
  height: "230px", 
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  
}));

const CenteredNumber = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
}));

const iconStyles = [
  { icon: <LanguageIcon />, backgroundColor: "#5D64FB" },
  { icon: <LinkedInIcon />, backgroundColor: "#30AEFF" },
  { icon: <InstagramIcon />, backgroundColor: "#25D9C8" }, 
  { icon: <FacebookIcon />, backgroundColor: "#29D372" }, 
];

const TraficOrigin = () => {
  return (
     <Box>
      <Card sx={{backgroundColor: "#D9D9D9",  borderRadius: "12px",
          padding: 2, maxWidth: "330px", height: "375px"  }}>
        <Container>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 2 }}>
            <LanguageTwoToneIcon sx={{ mr: 1 }} /> Origen del Tráfico
          </Typography>

          <ChartContainer>
            <Doughnut data={data} options={options} />
            <CenteredNumber>
              <Typography variant="h4" sx={{ fontWeight: "bold", fontSize: "24px" }}>
                100%
              </Typography>
            </CenteredNumber>
          </ChartContainer>

          <Box display="flex" justifyContent="center" alignItems="center" gap={4} mt={2}>
            {iconStyles.map((style, index) => (
              <IconButton
                key={index}
                sx={{ backgroundColor: style.backgroundColor, color: "#FFFFFF","&:hover": 
                  { backgroundColor: style.backgroundColor,
                    opacity: 0.8,
                  },
                  width: 36,
                  height: 36,
                  borderRadius: "8px",
                }}
              >
                {style.icon}
              </IconButton>
            ))}
          </Box>
        </Container>
      </Card>
    </Box>
  );
};

export default TraficOrigin;

