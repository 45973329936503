import React from 'react';
import { Box, Button, TextField, Typography, Link } from '@mui/material';
import { ButtonPrimary } from '../../components/UI/button/button';
import { FONT_COLOR_GRAY } from '../../components/constant/Colors';
import styled from '@emotion/styled';
import { Card, Container, Item } from '../../components/layout/layout';
const fontFamily = 'Inter';

const TextGray = styled(Typography)({
    fontSize: 18,
    fontFamily: fontFamily,
    fontWeight: 400,
    color: FONT_COLOR_GRAY,
});

const TextGrayBold = styled(Typography)({
    fontSize: 18,
    fontFamily: fontFamily,
    fontWeight: 700,
    color: FONT_COLOR_GRAY,
});

const FormRegister = () => {
  return (
    <Card>
      <Container justifyContent={"center"}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
          <Typography variant="h4" fontWeight="bold">Crear tu cuenta</Typography>
          <Typography variant="body2" color="textSecondary">Lorem ipsum dolor sit amet</Typography>
          <TextField label="Nombre" placeholder="Placeholder" fullWidth />
          <TextField label="Email" placeholder="Placeholder" fullWidth />
          
          <ButtonPrimary variant="contained" type='submit' fullWidth >
            Validar correo
          </ButtonPrimary>
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 1 }}>
            <Typography variant="body2">¿Ya tienes una cuenta?</Typography>
            <Link href="/" variant="body2">
              <TextGrayBold>
                Iniciar sesión
              </TextGrayBold>
            </Link>
          </Box>
        </Box>
      </Container>
    </Card>
  );
}

export default FormRegister;
