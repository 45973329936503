import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Box, Grid, styled } from "@mui/material";
import SupportIcon from '@mui/icons-material/Support'; // Ejemplo de ícono
import CustomizationIcon from '@mui/icons-material/Settings'; // Ejemplo de ícono
import FocusIcon from '@mui/icons-material/CenterFocusStrong'; // Ejemplo de ícono
import TypographyColumns from "../../../components/cabeceras/vertical/verticalUno/VerticalUno";

const typographyData = [
  {
    variant: "body1",
    color: "#B51AD8",
    textTransform: "uppercase",
    fontSize: { xs: "16px", sm: "18px", md: "18px", lg: "18px" },
    maxWidth: "40%",
    text: "¿Por qué elegirnos?",
    fontWeight: 500,
  },
  {
    variant: "body1",
    textTransform: "uppercase",
    fontSize: { xs: "36px", sm: "42px", md: "52px", lg: "52px" },
    maxWidth: { xs: "80%", md: "60%" },
    text: "Por qué somos tu mejor opción",
    fontWeight: "bold",
    lineHeight: {
      xs: "45px",
      sm: "50px",
      md: "58px",
      lg: "58px",
      xl: "58px",
    },
  },
];

const StepCard = styled(Card)`
  background-color: #0a0a0a;
  color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: radial-gradient(circle at bottom left, #7311BB 1%, transparent 35%),
              radial-gradient(circle at top right, #7311BB 1%, transparent 35%);
  position: relative;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  height: 250px;
  max-width: 100%;
`;

const StepTitle = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-align: left;
`;

const StepDescription = styled(Typography)`
  font-size: 14px;
  color: #cccccc;
`;

const ServiceCard = () => {
  return (
    <Box
      sx={{
        paddingTop: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: { xs: 5, lg: 12 },
        width: { lg: "98vw" },
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TypographyColumns data={typographyData} />
      </Box>

      <Grid
        container
        spacing={3}
        sx={{
          width: "80%",
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {[...Array(3)].map((_, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
            <StepCard
              sx={{
                padding: "20px 50px",
                height: { xs: "auto", lg: "210px" },
                border: "1px solid rgba(255, 255, 255, 0.50)",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                {/* Agregamos el ícono respectivo */}
                {index === 0 && <CustomizationIcon sx={{ fontSize: 50, color: "#ffffff", marginRight: "10px" }} />}
                {index === 1 && <SupportIcon sx={{ fontSize: 50, color: "#ffffff", marginRight: "10px" }} />}
                {index === 2 && <FocusIcon sx={{ fontSize: 50, color: "#ffffff", marginRight: "10px" }} />}
                
                <StepTitle>
                  {index === 0 && "Soluciones a Medida"}
                  {index === 1 && "Soporte Dedicado"}
                  {index === 2 && "Enfoque Centrado en el Cliente"}
                </StepTitle>
              </Box>

              <StepDescription sx={{ marginBottom: 1 }}>
                {index === 0 && "Ofrecemos soluciones personalizadas adaptadas a tus objetivos, audiencia y desafíos específicos."}
                {index === 1 && "Tu éxito es nuestra prioridad y estaremos aquí para apoyarte en cada paso del camino."}
                {index === 2 && "Sus ideas son nuestra prioridad. Nos dedicamos a comprender sus objetivos comerciales."}
              </StepDescription>
            </StepCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ServiceCard;
