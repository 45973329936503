import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu"; // Importa el icono de menú
import LogoSrc from "../../../../assets/logohitpoly.png"; // Asegúrate de que la ruta del logo es correcta

const ImagenConFondoDegradado = ({ quoteText, author }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Box
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        position: "relative",
        borderRadius: "25px",
        padding: "20px",
        background: `
                    radial-gradient(circle at top left, #7311BB 1%, transparent 50%),
                    radial-gradient(circle at bottom right, #7311BB 10%, transparent 50%)
                  `,
        backgroundRepeat: "no-repeat",
        width: '100%',
        color: "#ECEAEF",
      }}
    >
      {/* Contenido del componente */}
      <Box
        sx={{
          zIndex: 1000,
          backgroundColor: "#7311bb",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Sombra sutil
          borderRadius: "25px",
          padding: "20px",
        }}
      >
        {/* Contenedor para el logo y el icono del menú */}
        <Box
          sx={{
            top: "20px",
            left: "20px",
            right: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            backgroundColor: "transparent",
          }}
        >
          {/* Logo a la izquierda */}
          <Box
            component="img"
            src={LogoSrc}
            alt="Logo"
            sx={{ height: "30px", width: "auto" }} // Ajusta el tamaño según sea necesario
          />
          {/* Icono de menú hamburguesa a la derecha */}
          <IconButton
            sx={{
              color: "#ffffff",
              "&:hover": {
                color: "#ffffff", // Cambia a un color diferente al pasar el mouse
              },
            }}
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            marginTop: "80px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h5"
            component="p"
            sx={{
              fontWeight: "bold",
              maxWidth: "90%",
              fontFamily: "'Poppins', sans-serif",
              fontSize: "38px",
              textTransform: "uppercase",
              marginBottom: "15px",
              lineHeight: 1.2,
            }}
          >
            {quoteText} {/* Muestra el texto sin comillas */}
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            sx={{ fontWeight: "500" }}
          >
            - {author}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ImagenConFondoDegradado;
