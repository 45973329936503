import React from 'react';
import { Button as ButtonMaterial } from '@mui/material';
import { PRIMARY_COLOR } from '../../constant/Colors';

export const Button = ({
    primary = false,
    size = 'medium',
    label,
    type = 'button',
    ...props
}) => {
    return (
        <ButtonMaterial
            size={size}
            style={{
                fontSize: 18,
                height: 56,
                width: '100%',
                fontWeight: 700,
                textTransform: 'none',
                borderRadius: 8,
                color: !primary ? PRIMARY_COLOR : 'white',
                borderColor: !primary ? PRIMARY_COLOR : undefined,
                backgroundColor: primary ? PRIMARY_COLOR : undefined
            }}
            variant={primary ? 'contained' : 'outlined'}
            type={type}
            {...props}
        >
            {label}
        </ButtonMaterial>
    );
};

export const ButtonPrimary = ({
    children,
    type = 'button',
    ...props
}) => {
    return (
        <Button
            type={type}
            primary
            label={children}
            {...props}
        />
    );
};
