import React from 'react';
import DiseñoWeb from '../../../../internalPages/diseñoWeb/pages.jsx';

const ServicioDesarrolloWeb = () => {
  const title = "DESARROLLO DE PÁGINAS WEB DE ALTA CONVERSIÓN";
  const subtitle = "TRANSFORMA VISITAS EN CLIENTES";

  const introParagraph = `En HitPoly, creamos páginas web enfocadas en maximizar conversiones. Nos especializamos en el desarrollo de sitios optimizados para ofrecer una experiencia de usuario intuitiva, generar confianza, y convertir visitas en clientes. Ya sea que necesites una landing page o una web corporativa completa, nuestro enfoque siempre está en diseñar para el éxito.`; 

  const sectionsData = [
    {
      title: "Diseño Centrado en el Usuario",
      description: `Nuestro equipo diseña sitios web atractivos y fáciles de navegar, siempre con el usuario en mente. Creamos interfaces que guían a los usuarios hacia la conversión, asegurando que encuentren lo que buscan de manera rápida y efectiva.`,
      imageSrc: 'images/Diseño-centrado-en-el-usuario.jpg',
      imageAlt: 'Diseño centrado en el usuario',
    },
    {
      title: "Optimización para la Conversión (CRO)",
      description: `Implementamos estrategias avanzadas de Optimización de la Tasa de Conversión (CRO), probando y ajustando cada detalle del sitio para maximizar el número de visitantes que se convierten en clientes. Desde botones de llamado a la acción hasta formularios simplificados, todo se optimiza para obtener resultados.`,
      imageSrc: 'images/OptimizaciónParaLaConversión.jpg',
      imageAlt: 'Optimización para la conversión',
    },
    {
      title: "Velocidad y Rendimiento",
      description: `La velocidad del sitio es crucial para la conversión. Nos aseguramos de que tu página web cargue rápidamente en todos los dispositivos, reduciendo la tasa de abandono y mejorando la experiencia del usuario. Utilizamos las mejores prácticas para garantizar tiempos de carga mínimos.`,
      imageSrc: 'images/VelocidadYRendimiento.jpg',
      imageAlt: 'Velocidad y rendimiento',
    },
    {
      title: "Optimización SEO",
      description: `Desarrollamos sitios web con una estructura optimizada para SEO, lo que facilita que los motores de búsqueda indexen y clasifiquen tu página web. Esto incrementa tu visibilidad en línea y atrae tráfico de alta calidad que está listo para convertir.`,
      imageSrc: 'images/OptimizaciónSeo.jpg',
      imageAlt: 'Optimización SEO',
    },
    {
      title: "Análisis y Mejora Continua",
      description: `Utilizamos herramientas de análisis avanzadas para monitorear el rendimiento de tu página web. Realizamos pruebas A/B y mejoras continuas para garantizar que el sitio siempre esté optimizado para convertir al máximo su potencial.`,
      imageSrc: 'images/DesarrolloPruebas.jpg',
      imageAlt: 'Análisis y mejora continua',
    },
  ];

  const handleCtaAction = () => {
    window.location.href = "https://meetings.hubspot.com/hitpoly"; // Reemplaza con tu URL
  };

  return (
    <DiseñoWeb 
      title={title}
      subtitle={subtitle}
      sections={sectionsData}
      introParagraph={introParagraph}
      outroParagraph={(
        <>
          En HitPoly, creemos que un buen diseño de página web no solo debe ser visualmente atractivo, sino que también debe estar orientado a resultados. Nos enfocamos en la creación de experiencias de usuario que conduzcan a la conversión, ayudando a las marcas a alcanzar sus metas comerciales de manera efectiva.
          <br /><br />
          Cada página web que diseñamos está pensada para ser rápida, fácil de usar y optimizada para generar los mejores resultados. Ya sea que estés buscando generar leads, vender productos o aumentar la conciencia de tu marca, nuestro equipo tiene la experiencia para ayudarte a alcanzar el éxito en línea.
          <br /><br />
          ¡No te conformes con un sitio web promedio! Haz tu primera consulta con nosotros y descubre cómo podemos transformar tu presencia en línea y llevarla a un nivel superior con nuestro enfoque en la conversión.
          <br /><br />
          ¡El éxito online está a un clic de distancia! Contáctanos y comencemos a desarrollar tu página web de alta conversión hoy mismo.
        </>
      )}
      ctaText="¡Haz tu primera consulta con nosotros!"
      ctaAction={handleCtaAction} 
    />
  );
}

export default ServicioDesarrolloWeb;
