import React from "react";
import { Card, CardContent, CardMedia, Typography, Box, Button } from "@mui/material";
import ArrowOutward from "@mui/icons-material/ArrowOutward";

const CustomCard = ({ title, description, category, image, link, logo, circleBackground }) => {
  return (
    <Card
      sx={{
        maxWidth: 380,
        borderRadius: "15px",
        background: "#7311bb",
        margin: "20px auto",
        display: "flex",
        flexDirection: "column",
        height: "580px",
        cursor: "pointer",
        transform: "translateY(-10px)",
        boxShadow: "0 15px 25px rgba(0, 0, 0, 0.2)",
        transition:
          "transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease",
        "&:hover": {
          background: "#ECEAEF",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          transform: "translateY(0)",
          transition: "background 0.3s ease, box-shadow 0.3s ease",
          "& .text-hover": {
            color: "#7311bb",
          },
          "& .button-hover": {
            backgroundColor: "#7311bb",
            color: "#ECEAEF",
          },
          "& .description-hover": {
            color: "black",
          },
        },
      }}
    >
      {/* Caja de la imagen con tamaño fijo */}
      <Box
        sx={{
          position: "relative",
          width: "100%", 
          height: "220px", // Altura fija para la imagen
          borderRadius: "15px 15px 0 0",
          overflow: "hidden",
          "&:hover img": {
            opacity: 0.9,
          },
        }}
      >
        <CardMedia
          component="img"
          image={image}
          alt={title}
          sx={{
            objectFit: "cover",
            transition: "opacity 0.3s ease",
            height: "100%",
            width: "100%",
          }}
        />
      </Box>

      {/* Caja del logo con tamaño fijo y centrada */}
      <Box
        sx={{
          position: "absolute", 
          top: "160px", // Ajustado para que el logo quede sobre la imagen
          left: "50%", 
          transform: "translateX(-50%)", 
          zIndex: 10,
          width: "100px", // Tamaño fijo del logo
          height: "100px", // Tamaño fijo del logo
          borderRadius: "50%", 
          background: circleBackground || "#ffffff", // Color dinámico
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", 
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{
            width: "70%", 
            height: "70%", 
            objectFit: "contain", // Ajusta la imagen dentro del círculo sin distorsión
          }}
        />
      </Box>

      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          color: "#ECEAEF",
          transition: "background 0.3s ease, color 0.3s ease",
        }}
      >
        <Typography
          variant="body1"
          className="text-hover"
          sx={{
            mb: 1,
            mt: 6,
            color: "#ECEAEF",
            transition: "color 0.3s ease",
          }}
        >
          {category}
        </Typography>
        <Typography
          variant="h6"
          className="text-hover"
          sx={{
            mb: 2,
            color: "#ECEAEF",
            fontWeight: "500",
            fontSize: "1.2rem",
            transition: "color 0.3s ease",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          className="description-hover"
          sx={{
            mb: 2,
            color: "#ECEAEF",
            transition: "color 0.3s ease",
          }}
        >
          {description}
        </Typography>
      </CardContent>

      <Box sx={{ padding: "16px", display: "flex", justifyContent: "center" }}>
        <Button
          component="a"
          href={link}
          target="_blank"
          variant="contained"
          className="button-hover"
          sx={{
            backgroundColor: "#ECEAEF",
            color: "#7311bb",
            borderRadius: "20px",
            textTransform: "none",
            transition: "background-color 0.3s ease, color 0.3s ease",
            "&:hover": {
              backgroundColor: "white",
              color: "#ffffff",
            },
          }}
        >
          Leér más
          <ArrowOutward sx={{ fontSize: "18px", marginLeft: "10px" }} />
        </Button>
      </Box>
    </Card>
  );
};

export default CustomCard;
