import { Box } from "@mui/material";
import { motion } from "framer-motion"; // Importamos framer-motion
import { useInView } from "react-intersection-observer"; // Hook para detectar cuando el componente entra en vista
import CardSection from "./components/CardSection";
import Navbar from "../navbarHomeGral/navbarGral";
import SectionService from "./components/servicios/SectionService";
import ServiceReviews from "./components/ServiceReviews";
import ComunityPublic from "./components/comunitypublic";
import StatisticsSection from "./components/StatisticsSection";
import PortadaHome from "./components/portada/PortadaHome";
import Footer from "../footer/page";

const Home = () => {
  const { ref: portadaRef, inView: portadaInView } = useInView({
    triggerOnce: true, // Se activa solo una vez
    threshold: 0.1, // Cuando el 10% es visible
  });

  const { ref: statsRef, inView: statsInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: cardRef, inView: cardInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: serviceRef, inView: serviceInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: reviewsRef, inView: reviewsInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: communityRef, inView: communityInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: footerRef, inView: footerInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Box sx={{ overflow: "hidden" }}>
      {/* Barra de navegación */}
      <Navbar />
      {/* Sección de Portada */}
      <motion.div
        ref={portadaRef}
        initial={{ opacity: 0, y: 20 }} // Desplazamiento pequeño y opacidad 0
        animate={{ opacity: portadaInView ? 1 : 0, y: portadaInView ? 0 : 20 }} // Animación suave de abajo hacia arriba
        transition={{ duration: 0.4 }} // Duración más corta para una animación más rápida
      >
        <Box sx={{ marginTop: "50px", marginBottom: { xs: "50px", md: "10px" } }}>
          <PortadaHome />
        </Box>
      </motion.div>

      {/* Sección de tarjeta de crecimiento */}
      <motion.div
        ref={statsRef}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: statsInView ? 1 : 0, y: statsInView ? 0 : 20 }}
        transition={{ duration: 0.4 }}
      >
        <Box sx={{ marginBottom: { xs: "50px", md: "100px" } }}>
          <StatisticsSection />
        </Box>
      </motion.div>

      {/* Sección de Tarjetas */}
      <motion.div
        ref={cardRef}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: cardInView ? 1 : 0, y: cardInView ? 0 : 20 }}
        transition={{ duration: 0.4 }}
      >
        <CardSection />
      </motion.div>

      {/* Sección de Cuidado y Servicio */}
      <motion.div
        ref={serviceRef}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: serviceInView ? 1 : 0, y: serviceInView ? 0 : 20 }}
        transition={{ duration: 0.4 }}
      >
        <SectionService />
      </motion.div>

      {/* Sección de Reseñas */}
      <motion.div
        ref={reviewsRef}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: reviewsInView ? 1 : 0, y: reviewsInView ? 0 : 20 }}
        transition={{ duration: 0.4 }}
      >
        <ServiceReviews />
      </motion.div>

      {/* Sección de Comunidad */}
      <motion.div
        ref={communityRef}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: communityInView ? 1 : 0, y: communityInView ? 0 : 20 }}
        transition={{ duration: 0.4 }}
      >
        <ComunityPublic />
      </motion.div>

      {/* Pie de Página */}
      <motion.div
        ref={footerRef}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: footerInView ? 1 : 0, y: footerInView ? 0 : 20 }}
        transition={{ duration: 0.4 }}
      >
        <Footer />
      </motion.div>
    </Box>
  );
};

export default Home;
