import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Desarrollo from "../../../assets/Desarrollo.webp";

const FeaturedArticles = () => {
  const articles = [
    {
      title: "marketing digital 12 DE SEPTIEMBRE DE 2024",
      subtitle: "Scamper: qué es y cómo se usa para resolver problemas",
      description:
        "Al aplicar cada uno de estos componentes a un problema o proyecto, puedes descomponerlo en sus elementos fundamentales y reconfigurarlos de maneras novedosas. Ya sea que estés desarrollando un nuevo producto, mejorando un proceso empresarial o buscando formas de revitalizar una campaña de marketing.",
      image: Desarrollo,
    },
    {
      title: "marketing digital 12 DE SEPTIEMBRE DE 2024",
      description: "Scamper: qué es y cómo se usa para resolver problemas",
    },
    {
      title: "marketing digital 12 DE SEPTIEMBRE DE 2024",
      description: "Scamper: qué es y cómo se usa para resolver problemas",
    },
    {
      title: "marketing digital 12 DE SEPTIEMBRE DE 2024",
      description: "Scamper: qué es y cómo se usa para resolver problemas",
    },
    {
      title: "marketing digital 12 DE SEPTIEMBRE DE 2024",
      description: "Scamper: qué es y cómo se usa para resolver problemas",
    },
  ];
  return (
    <Box sx={{ padding: "40px",}}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={7}>
          <Card
            sx={{
              borderbackgroundColor: "#2C2C2C",
              color: "#FFFFFF",
              background: `radial-gradient(circle at bottom right, #7311BB 10%, transparent 40%),
              radial-gradient(circle at top left, #7311BB 1%, transparent 0%)`,
              borderRadius: "20px",
              height: "100%",
              border: "1px solid #828080",
              margin: "0 auto",
            }}
          >
            <CardMedia
              component="img"
              height="300   "
              image={articles[0].image}
              alt={articles[0].title}
              sx={{ objectFit: "cover" }}
            />
            <CardContent sx={{ color: "white" }}>
              <Typography
                variant="Body1"
                sx={{ color: "#B51AD8", marginBottom: "20px" }}
              >
                {articles[0].title}
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", marginBottom: "20px" }}
              >
                {articles[0].subtitle}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: "1rem" }}>
                {articles[0].description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={5}>
          <Box
            sx={{
              borderbackgroundColor: "#2C2C2C",
              color: "#FFFFFF",
              background: `radial-gradient(circle at bottom right, #7311BB 10%, transparent 40%),
              radial-gradient(circle at top left, #7311BB 1%, transparent 0%)`,
              borderRadius: "20px",
              border: "1px solid #828080",
              padding: "40px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "white",
                fontWeight: "bold",
                borderBottom: "3px solid #B51AD8",
              }}
            >
              ARTÍCULOS DESTACADOS
            </Typography>
            <List>
              {articles.slice(1).map((article, index) => (
                <ListItem
                  key={index}
                  sx={{ borderBottom: "3px solid #3C3C3C" }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body2" sx={{ color: "#B51AD8" }}>
                        {article.title}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="h6">
                        {article.description}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeaturedArticles;
