import React from "react";
import { Box } from "@mui/material";
import transformacionDigital from "../../../assets/transformacionDigital.jpg";
import inteligenciaArtificial from "../../../assets/inteligencia-artificial.jpg";
import socialmediamarketinG from "../../../assets/socialmediamarketinG.webp";
import TypographyColumns from "../../components/cabeceras/vertical/verticalUno/VerticalUno";
import CardDeArticulosMasVistos from "../../components/cardDeArticulosMasVistos/cardDeArticulosMasVistos"; // Importa el nuevo componente

const typographyData = [
  {
    variant: "body1",
    color: "#B51AD8",
    textTransform: "uppercase",
    fontSize: { xs: "16px", sm: "18px", md: "18px", lg: "18px" },
    maxWidth: "40%",
    text: "COMUNICANDO A NUESTRO PÚBLICO",
    fontWeight: 500,
  },
  {
    variant: "body1",
    textTransform: "uppercase",
    fontSize: { xs: "36px", sm: "42px", md: "52px", lg: "52px" },
    maxWidth: { xs: "80%", md: "60%" },
    text: "ENCUENTRA LOS ARTÍCULOS QUE MARCAN LA DIFERENCIA",
    fontWeight: "bold",
    lineHeight: {
      xs: "45px",
      sm: "50px",
      md: "58px",
      lg: "58px",
      xl: "58px",
    },
  },
];

const ComunityPublic = () => {
  return (
    <Box
      sx={{
        padding: { xs: "30px 0", sm: "30px 0", md: "40px 0" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ marginBottom: "50px" }}>
        <TypographyColumns data={typographyData} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: "30px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CardDeArticulosMasVistos
          title="Transformación Digital: Cómo Adaptarse a un Mundo en Línea"
          description="Descubre las estrategias necesarias para que tu empresa se transforme digitalmente y mejore su competitividad."
          image={transformacionDigital}
          link="https://wa.me/51933961352"
        />
        <CardDeArticulosMasVistos
          title="Marketing de Contenidos: La Clave para Atraer y Retener Clientes"
          description="Aprende las mejores prácticas en marketing de contenidos para 2024 y cómo mantener a tus clientes comprometidos."
          image={socialmediamarketinG}
          link="https://wa.me/51933961352"
        />
        <CardDeArticulosMasVistos
          title="Inteligencia Artificial en el Marketing: Cómo Usarla para Potenciar Tu Negocio"
          description="Explora cómo la inteligencia artificial puede personalizar la experiencia del cliente y automatizar procesos en tu negocio."
          image={inteligenciaArtificial}
          link="https://wa.me/51933961352"
        />
      </Box>
    </Box>
  );
};

export default ComunityPublic;
