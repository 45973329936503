import React from 'react';
import DiseñoWeb from '../../../../internalPages/diseñoWeb/pages.jsx';

const ServicioMarketingContenidos = () => {
  // Información general del servicio
  const title = "MARKETING DE CONTENIDOS";
  const subtitle = "DESCRIPCIÓN DEL SERVICIO";

  // Descripción del servicio
  const introParagraph = `En HitPoly, entendemos que el Marketing de Contenidos es una estrategia esencial para atraer, educar y convertir a tu audiencia. Creamos y distribuimos contenido valioso y relevante, logrando una conexión genuina con tu público. Nuestro enfoque posiciona a tu marca como líder en su industria, fortaleciendo la lealtad del cliente a través de contenido consistente y de alta calidad.`;

  // Proceso de desarrollo
  const sectionsData = [
    {
      title: "Investigación y Planificación",
      description: `Comenzamos con una auditoría de los contenidos actuales para detectar áreas de mejora. Analizamos a fondo a tu audiencia para entender sus intereses, necesidades y comportamientos. Definimos metas claras y medibles, siempre alineadas con los objetivos de tu negocio.`,
      imageSrc: 'images/MarketingInvestigacion.jpg',
      imageAlt: 'Investigación y Planificación',
    },
    {
      title: "Creación de Contenidos",
      description: `Desarrollamos una estrategia detallada, que incluye los tipos de contenido, temas relevantes, calendarios editoriales y los mejores canales de distribución. Producimos contenido de calidad, como blogs, artículos, videos, infografías, ebooks y podcasts, optimizados para motores de búsqueda, garantizando una mayor visibilidad.`,
      imageSrc: 'images/MarketingDeContenido.jpg',
      imageAlt: 'Creación de Contenidos',
    },
    {
      title: "Distribución y Promoción",
      description: `Ampliamos el alcance del contenido a través de redes sociales y generamos interacción con tu audiencia. Diseñamos campañas segmentadas de email marketing para entregar contenido personalizado. Además, colaboramos con influencers y socios estratégicos para aumentar la visibilidad de tu contenido.`,
      imageSrc: 'images/MarketingPublicidad.jpg',
      imageAlt: 'Distribución y Promoción',
    },
    {
      title: "Monitoreo y Análisis",
      description: `Medimos constantemente la efectividad del contenido a través de herramientas de análisis para asegurar su rendimiento óptimo. Recogemos feedback de tu audiencia y ajustamos la estrategia para maximizar su impacto y relevancia.`,
      imageSrc: 'images/MonitoreoYAnalisis.jpg',
      imageAlt: 'Monitoreo y Análisis',
    },
    {
      title: "Beneficios",
      description: `Gracias a nuestras estrategias, verás un aumento notable en la visibilidad de tu marca y en el tráfico web. El contenido que creamos atrae y convierte a los visitantes en clientes potenciales, ayudando a que tu negocio crezca de manera constante. Al ofrecer contenido valioso y relevante, fomentamos la fidelidad de los clientes y posicionamos a tu marca como una autoridad en tu industria.`,
      imageSrc: 'images/MarketingBeneficios.jpg',
      imageAlt: 'Beneficios',
    },
  ];

  const handleCtaAction = () => {
    window.location.href = "https://meetings.hubspot.com/hitpoly";
  };

  return (
    <DiseñoWeb 
      title={title}
      subtitle={subtitle}
      sections={sectionsData}
      introParagraph={introParagraph}
      outroParagraph={(
        <>
          En HitPoly, más que implementar estrategias de contenido, construimos relaciones duraderas con tu audiencia. Queremos que tu marca no solo sea reconocida, sino que también sea admirada y valorada por su autenticidad y consistencia. Descubre cómo una estrategia de contenido bien pensada puede transformar tu negocio y crear un impacto positivo en tu sector.
          <br /><br />
          No se trata solo de proporcionar información; se trata de empoderar a tus clientes para tomar decisiones más acertadas. Esto no solo impulsa el crecimiento de tu negocio, sino que también promueve prácticas empresariales responsables y sostenibles que benefician tanto a tu marca como a la comunidad.
          <br /><br />
          El mundo digital sigue avanzando a gran velocidad y tu negocio no puede quedarse atrás. Con el marketing de contenidos, mejorarás la visibilidad de tu marca y conectarás de manera más efectiva con tu audiencia, impulsando la innovación y fomentando la adaptabilidad en tu empresa.
          <br /><br />
          No pierdas la oportunidad de llevar tu empresa al siguiente nivel. Haz tu primera consulta con nosotros hoy mismo y descubre cómo nuestras soluciones personalizadas pueden marcar una diferencia significativa para tu marca.
          <br /><br />
          El futuro digital está más cerca de lo que imaginas. Contáctanos y juntos haremos realidad la visión que tienes para tu marca.
        </>
      )}
      ctaText="¡Haz tu primera consulta con nosotros!"
      ctaAction={handleCtaAction} 
    />
  );
}

export default ServicioMarketingContenidos;
