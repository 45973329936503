import React from "react";
import { Box, Typography, Card, Grid, Button } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const Homepage = () => {
  return (
    <Grid container>
      <Box
        sx={{
          color: "#ffffff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: { xs: "10px", sm: "20px", md: "40px", xl: "20px 40px" },
          width: "100%",
        }}
      >
        <Card
          sx={{
            borderRadius: "15px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "inherit",
            alignItems: "self-start",
            color: "#ffffff",
            padding: { xs: "20px", sm: "30px", md: "20px" },
            marginBottom: { xs: "50px", md: "20px" },
            width: { xs: "95%", md: "95%", lg: "97%" },
            height: { xs: "300px", md: "200px", lg: "250px" },
            textAlign: "center",
            background: "transparent",
            position: "relative",
            overflow: "hidden",
            border: "1px solid rgba(255, 255, 255, 0.2)",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",

            "&::before, &::after": {
              content: '""',
              position: "absolute",
              width: "450px",
              height: "450px",
              borderRadius: "50%",
              background:
                "radial-gradient(circle, rgba(115, 17, 187, 0.7), transparent 60%)",
              transition: "background 0.3s",
            },
            "&::before": {
              top: "10px",
              left: "-180px",
            },
            "&::after": {
              bottom: "10px",
              right: "-180px",
            },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              marginTop: "10px",
              color: "rgba(255, 255, 255, 0.7)",
              fontSize: {
                xs: "0.6rem",
                sm: "0.6rem",
                md: "0.8rem",
                lg: "0.8rem",
              },
              marginBottom: "10px",
            }}
          >
            Blog - Marketing - Marketing B2B: concepto, estrategias y ejemplos
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "1.5rem", sm: "1.rem", md: "1.80rem", lg: "2.6rem" },
              marginBottom: "20px",
            }}
          >
            MARKETING B2B: CONCEPTO, ESTRATEGIAS Y EJEMPLOS
          </Typography>

          <Button
            variant="contained"
            sx={{
                backgroundColor: '#FFFFFF',
                color: '#000000',
                width: {xs:"100%", sm: "50%", md: "47%", lg: "35%",},
                borderRadius: '10px',
                textTransform: 'none',
                fontSize: "3rem",
                display: 'flex',
                padding: { xs: "30px", sm: "20px", md: "20px", },
                height: { xs: "10px", md: "10px", lg: "40px" },
                "&:hover": {
                backgroundColor: "#65538d", 
              },
            }}
          >
            <FileDownloadOutlinedIcon
              sx={{
                backgroundColor: "#7B1FA2",
                borderRadius: "5px",
                color: "#FFFFFF",
                marginRight: "5px", 
                fontSize: "26px", 
              }}
            />
            Descargar guía estratégica de marketing
          </Button>

          <Typography
            variant="body2"
            sx={{
              marginTop: "10px",
              color: "rgba(255, 255, 255, 0.7)",
              fontSize: "0.9rem",
            }}
          >
            Publicado: 29 de enero de 2024
          </Typography>
        </Card>
      </Box>
    </Grid>
  );
};

export default Homepage;
