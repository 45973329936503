import React from "react";
import Registration from "../../../../assets/hk-registration.png";
import Hihome from "../../../../assets/logo-hihome.webp";
import Mark from "../../../../assets/mark-and-vetty.png";
import ArrowOutward from "@mui/icons-material/ArrowOutward";
import TypographyColumns from "../../../components/cabeceras/vertical/verticalUno/VerticalUno";
import {
  Box,
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";

const typographyData = [
  {
    variant: "body1",
    color: "#B51AD8",
    textTransform: "uppercase",
    fontSize: { xs: "16px", sm: "18px", md: "18px", lg: "18px" },
    maxWidth: "40%",
    text: "Prueba de proyectos",
    fontWeight: 500,
  },
  {
    variant: "body1",
    textTransform: "uppercase",
    fontSize: { xs: "36px", sm: "42px", md: "52px", lg: "52px" },
    maxWidth: { xs: "80%", md: "60%" },
    text: "Algunas muestras de nuestros trabajos",
    fontWeight: "bold",
    lineHeight: {
      xs: "45px",
      sm: "50px",
      md: "58px",
      lg: "58px",
      xl: "58px",
    },
  },
];

const servicesData = [
  {
    title: "HK-Registration",
    description:
      "Brindamos servicios de asesoramiento jurídico y contable con el objetivo de asegurar la conformidad legal y optimizar la gestión empresarial. Guiamos a nuestros clientes a que impulsen su éxito y tranquilidad.",
    category: "Publicidad Digital",
    image: Registration,
    link: "https://hkregistration.com/en/", // Enlace dinámico
  },
  {
    title: "Hi Home RD",
    description:
      "Hi Home RD es una empresa líder en consultoría inmobiliaria y financiera en la República Dominicana. Ofrecemos altos retornos de inversión y ganancias de capital para nuestros clientes.",
    category: "Community Manager",
    image: Hihome,
    link: "https://hihomerd.com/", // Enlace dinámico
  },
  {
    title: "Mark and Vetty",
    description:
      "Potenciamos tu empresa con una solución digital integral. Ofrecemos servicios que incluyen branding, desarrollo de páginas web, gestión de redes sociales, producción audiovisual y creatividad comercial.",
    category: "Desarrollo Web & App",
    image: Mark,
    link: "https://www.markandvetty.com", // Enlace dinámico
  },
];

const CardsProyects = () => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        marginTop: 15,
        padding: { xs: "0px 20px", md: "0px 100px" },
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ marginBottom: "50px" }}>
        <TypographyColumns data={typographyData} />
      </Box>
      {servicesData.map((service, index) => (
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={index}>
          <Card
            sx={{
              maxWidth: { xs: "100%", md: "84%", lg: "90%" },
              height: { xs: "auto", sm: "auto", md: "auto", lg: 530, xl: 500 },
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              color: "#FFFFFF",
              background: `radial-gradient(circle at bottom right, #7311BB 10%, transparent 40%),
                  radial-gradient(circle at top left, #7311BB 1%, transparent 0%)`,
              borderRadius: "20px",
              border: "1px solid #828080",
              margin: "10px 20px",
            }}
          >
            <Box
              sx={{
                position: "relative",
                height: 200,
                background: `radial-gradient(circle at bottom left, #7311BB 0%, transparent 50%), 
                    radial-gradient(circle at top right, #7311BB 1%, transparent 0%)`,
                borderRadius: "20px 20px 15px 15px",
                borderBottom: "1px solid #828080",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardMedia
                component="img"
                image={service.image}
                alt={service.title}
                sx={{
                  maxWidth: "70%",
                  maxHeight: "60%",
                  objectFit: "contain",
                  border: "1px solid #828080",
                  borderRadius: "10px",
                  padding: "20px",
                }}
              />
            </Box>

            <CardContent>
              <Typography variant="body1" sx={{ mb: 1, color: "#B51AD8" }}>
                {service.category}
              </Typography>
              <Typography
                variant="h6"
                sx={{ mb: 2, fontWeight: "bold", fontSize: "1.2rem" }}
              >
                {service.title}
              </Typography>
              <Typography variant="body2">{service.description}</Typography>
            </CardContent>

            {/* Botón "Ver Resultados" con enlace dinámico */}
            <Box sx={{ paddingBottom: "16px", ml: 2, mt: "auto" }}>
              <Button
                component="a"
                href={service.link} // Usamos el link dinámico aquí
                target="_blank"
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#6F4CE0",
                  borderRadius: "20px",
                  width: { xs: "90%", sm: "90%", md: "90%" },
                  textTransform: "none",
                  boxShadow: "0 4px 14px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    backgroundColor: "#3E1F7E",
                  },
                }}
                endIcon={<ArrowOutward />}
              >
                Ver Resultados
              </Button>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardsProyects;
