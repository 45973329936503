import React, { useState } from "react";
import { Box, IconButton, Popover, Grid, Typography } from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";

const MenuDeServicios = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  // Servicios para mostrar en el menú desplegable
  const services = [
    { name: "Gmail", icon: "📧" },
  ];

  return (
    <Box>
      {/* Botón con los 9 cuadraditos */}
      <IconButton onClick={handleOpen}>
        <AppsIcon sx={{ color: "white",}} />
      </IconButton>

      {/* Menú desplegable (Popover) */}
      <Popover
       
       sx={{marginTop: "10px",}}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ padding: 2,}}>
          <Grid container spacing={4} sx={{ width: 200, }}> {/* Espaciado aumentado */}
            {services.map((service, index) => (
              <Grid item xs={4} key={index}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                    marginBottom: 2, // Añadir margen inferior
                  }}
                >
                  <Typography variant="h4">{service.icon}</Typography>
                  <Typography variant="caption">{service.name}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Popover>
    </Box>
  );
};

export default MenuDeServicios;
