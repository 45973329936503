import React from "react";
import { Box, Card, Container, Typography } from "@mui/material";
import ceo from "../../../assets/ceo.png";
import logoInfo from "../../../assets/logoinfo.png";

const CeoCard = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: { xs: 5, lg: 12 },
        width: { lg: "98vw" },
      }}
    >
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            color: "#B51AD8",
            fontSize: { xs: "1.125rem", sm: "1.125rem", md: "1.25rem" },
          }}
        >
          NUESTRO CEO
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            mb: 3,
            color: "#ffffff",
            fontSize: {
              xs: "30px",
              sm: "2.5rem",
              md: "3rem",
              lg: "3.25rem",
            },
          }}
        >
          CONOCE AL FUNDADOR
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
          width: "80%",
        }}
      >
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid rgba(255, 255, 255, 0.50)",
            backgroundColor: "#0a0a0a",
            width: { xs: "90%", sm: "80%", md: "50%", lg: "40%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: `
              radial-gradient(circle at bottom left, #7311BB 1%, transparent 40%),
              radial-gradient(circle at top right, #7311BB 1%, transparent 40%)
            `,
          }}
        >
          <img
            src={ceo}
            alt="ceo"
            style={{ width: "100%", height: "auto", borderRadius: "15px" }}
          />
        </Card>

        <Box
          sx={{
            padding: { xs: "20px", sm: "30px", md: "40px" },
            color: "#ffffff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: { xs: "100%", sm: "80%", md: "60%" },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              mb: 2,
              fontSize: {
                xs: "30px",
                sm: "2.5rem",
                md: "3rem",
                lg: "3.25rem",
              },
            }}
          >
            DENIS OLIVARES
          </Typography>

          <Typography
            variant="body1"
            sx={{
              mb: 2,
              fontSize: { xs: "0.875rem", sm: "1rem", md: "0.9375rem" },
            }}
          >
            Denis Olivares no solo fundó Hitpoly, también lidera una revolución
            tecnológica con un propósito claro: cambiar el mundo a través de la
            innovación. Con la convicción de que "todo comienza en la mente
            antes de hacerse realidad", Denis se ha comprometido a luchar hasta
            el final para crear un futuro más equitativo y próspero para todos. <br></br> <br></br>Imparable y
            destructor de barreras, ha convertido su visión en acción, liderando
            con estrategias innovadoras que no solo transforman negocios, sino
            también vidas. Su misión es dejar una huella positiva en este
            planeta, utilizando su creatividad y tecnología para generar un
            impacto duradero en la humanidad. Denis no se detiene. Su ambición
            va más allá de lo común, buscando siempre el próximo reto, el
            próximo gran cambio que hará del mundo un lugar mejor.
          </Typography>

          <Box
            sx={{
              width: { xs: "100%", sm: "80%", md: "60%" },
              height: "auto",
              mt: 2,
            }}
          >
            <img
              src={logoInfo}
              alt="logoInfo"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CeoCard;
