import React from 'react';
import DiseñoWeb from '../../../../internalPages/diseñoWeb/pages.jsx';

const ServicioCampañasPublicitarias = () => {
  const title = "PUBLICIDAD DIGITAL";
  const subtitle = "IMPULSA TU MARCA EN EL MUNDO DIGITAL";

  const introParagraph = `En HitPoly, nuestro servicio de Publicidad Digital está diseñado para maximizar tu visibilidad en línea y generar un impacto tangible en tus resultados comerciales. Utilizamos una combinación de plataformas publicitarias, incluyendo Google Ads y Meta Ads, para llegar a tu audiencia de manera efectiva, garantizando que cada inversión se traduzca en resultados concretos y medibles.`;

  const sectionsData = [
    {
      title: "Estrategia de Publicidad",
      description: `Desarrollamos una estrategia personalizada que se alinea con tus objetivos comerciales. Realizamos un análisis exhaustivo de tu mercado y competencia para determinar las mejores plataformas y tácticas a utilizar. Esto asegura que tus campañas lleguen a la audiencia adecuada, en el momento adecuado.`,
      imageSrc: 'images/CampanasEstrategiaPublicitaria.jpg',
      imageAlt: 'Estrategia de Publicidad',
    },
    {
      title: "Configuración de Campañas",
      description: `Creamos anuncios visualmente atractivos y persuasivos que capturan la atención de tu audiencia. Implementamos opciones de segmentación avanzadas para dirigir tus anuncios a los clientes potenciales más relevantes, maximizando la tasa de conversión.`,
      imageSrc: 'images/CampanasConfiguracion.jpeg',
      imageAlt: 'Configuración de Campañas',
    },
    {
      title: "Monitoreo y Optimización",
      description: `Utilizamos herramientas de análisis para rastrear el rendimiento de tus campañas en tiempo real. Realizamos optimizaciones en función de los datos obtenidos para maximizar el rendimiento y garantizar que cada dólar gastado genere el mayor retorno posible.`,
      imageSrc: 'images/CampanasMonitoreoYOptimizacion.png',
      imageAlt: 'Monitoreo y Optimización',
    },
    {
      title: "Informe de Resultados",
      description: `Generamos informes detallados que destacan el rendimiento de tus campañas. Estos informes incluyen métricas clave como impresiones, clics, conversiones y retorno de inversión, permitiéndote tomar decisiones informadas sobre futuras estrategias publicitarias.`,
      imageSrc: 'images/CampanasResultados.jpg',
      imageAlt: 'Informe de Resultados',
    },
    {
      title: "Beneficios",
      description: `Nuestras campañas aseguran que tu marca se destaque en el mundo digital. La publicidad digital efectiva atrae a clientes potenciales, aumentando tu base de clientes. Con una segmentación precisa y anuncios atractivos, maximizamos tus conversiones y retorno de inversión.`,
      imageSrc: 'images/CampanasBeneficios.jpg',
      imageAlt: 'Beneficios',
    },
  ];

  const handleCtaAction = () => {
    window.location.href = "https://meetings.hubspot.com/hitpoly"; // Reemplaza con tu URL
  };

  return (
    <DiseñoWeb 
      title={title}
      subtitle={subtitle}
      sections={sectionsData}
      introParagraph={introParagraph}
      outroParagraph={(
        <>
          En HitPoly, la Publicidad Digital es más que un simple anuncio. Es una estrategia integral para impulsar tu marca en el entorno digital. Nos enfocamos en crear campañas efectivas que no solo generen clics, sino que también fomenten relaciones duraderas con tus clientes. A través de un enfoque basado en datos, garantizamos que cada campaña se optimice continuamente para alcanzar el mejor rendimiento.
          <br /><br />
          Nuestro compromiso es ayudarte a sobresalir en un mercado competitivo. Con nuestra experiencia en publicidad digital, aprenderás a aprovechar las oportunidades que ofrece el marketing en línea, mejorando así tu posicionamiento en el mercado.
          <br /><br />
          El mundo digital está en constante evolución, y tu negocio merece estar a la vanguardia. A través de nuestras soluciones de publicidad digital, te ayudamos a interactuar de manera efectiva con tu audiencia, impulsando la innovación y adaptabilidad en tu empresa.
          <br /><br />
          ¡No dejes pasar la oportunidad de llevar tu negocio al siguiente nivel! Haz tu primera consulta con nosotros y descubre cómo nuestra experiencia y soluciones personalizadas pueden marcar la diferencia para tu marca.
          <br /><br />
          ¡El futuro digital está a un clic de distancia! Contáctanos y juntos transformaremos tu visión en realidad.
        </>
      )}
      ctaText="¡Haz tu primera consulta con nosotros!"
      ctaAction={handleCtaAction} 
    />
  );
}

export default ServicioCampañasPublicitarias;
