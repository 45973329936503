import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Box, Grid, styled } from "@mui/material";

const StepCard = styled(Card)`
  background-color: #0a0a0a;
  color: #ffffff;
  padding: 20px; /* Ajusta el padding para pantallas móviles */
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: radial-gradient(circle at bottom left, #7311BB 1%, transparent 35%),
              radial-gradient(circle at top right, #7311BB 1%, transparent 35%);
  position: relative;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  height: 250px;
  max-width: 100%;
`;

const StepTitle = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-align: left;
`;

const StepNumber = styled(Typography)`
  font-size: 40px; 
  font-weight: bold;
  color: #ffffff;
  margin-right: 10px;
`;

const StepDescription = styled(Typography)`
  font-size: 14px;
  color: #cccccc;
`;

const StepGrid = () => {
  return (
    <Box sx={{ paddingTop: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: {xs: 5, lg:12},
      width: { lg:"98vw"},
     }}>
      <Box sx={{ textAlign: "center", display:"flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="h6" sx={{ mb: 2, color: "#B51AD8", fontSize: { xs: "1.125rem", sm: "1.125rem", md: "1.25rem" } }}>
          COMO TRABAJAMOS
        </Typography>

        <Typography variant="h3" sx={{ fontWeight: "bold", mb: 3, color: "#ffffff", fontSize: {
                  xs: "30px",
                  sm: "2.5rem",
                  md: "3rem",
                  lg: "3.25rem",
                },
                width: {xs: "90%", lg:"70%"},
                
                }}>
          NUESTRO PROCESO PARA MARCAS Y EMPRESAS DISTRIBUIDO EN ETAPAS
        </Typography>
      </Box>
      <Grid container spacing={1} sx={{
        width: "90%",
        gap: 7,
        marginTop: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        {[...Array(6)].map((_, index) => (
          <Grid item xs={12} sm={6} md={5} lg={5} key={index}>
            <StepCard sx={{padding: "20px 50px", height: {xs: "auto", lg: "250px", border: "1px solid rgba(255, 255, 255, 0.50)",}}}>
              <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                <StepNumber variant="h2">{`0${index + 1}`}</StepNumber>
                <StepTitle>
                  {index === 0 && "Consulta Inicial - Conectando tus Objetivos con Soluciones"}
                  {index === 1 && "Propuesta Personalizada - Diseñando tu Camino al Éxito"}
                  {index === 2 && "Planificación Estratégica - Estrategias que Impulsan Resultados"}
                  {index === 3 && "Implementación - De la Estrategia a la Acción"}
                  {index === 4 && "Optimización del Proceso Comercial - Crecimiento Sostenible y Escalable"}
                  {index === 5 && "Resultados y Escalabilidad - Maximiza el Éxito a Largo Plazo"}
                </StepTitle>
              </Box>
              <StepDescription 
              sx={{marginBottom: 1}}
              >
                {index === 0 && "Basándonos en la evaluación inicial, creamos una propuesta detallada que incluye estrategias personalizadas para alcanzar tus objetivos. Este es un plan claro y conciso que se adapta a las necesidades únicas de tu negocio, con un enfoque en resultados tangibles."}
                {index === 1 && "Basándonos en la evaluación inicial, creamos una propuesta detallada que incluye estrategias personalizadas para alcanzar tus objetivos. Este es un plan claro y conciso que se adapta a las necesidades únicas de tu negocio, con un enfoque en resultados tangibles."}
                {index === 2 && "Con el plan estratégico en marcha, es momento de implementar. Nos enfocamos en la creación de contenido valioso y campañas publicitarias que capten la atención de tu audiencia y generen conversiones. Nuestro equipo se encarga de cada detalle, asegurando que todo se ejecute con precisión."}
                {index === 3 && "Tras la aprobación, elaboramos un plan estratégico integral alineado con tus metas. Este plan es la base sobre la cual construiremos el éxito de tus campañas, asegurando que cada táctica esté perfectamente sincronizada para maximizar el impacto."}
                {index === 4 && "Refinamos continuamente el proceso comercial, utilizando herramientas avanzadas para asegurar que cada interacción con tus clientes sea eficiente y efectiva. Nuestro enfoque en la optimización del Buyer Journey garantiza que la satisfacción del cliente esté en su punto máximo."}
                {index === 5 && "Una vez alcanzados los primeros resultados, evaluamos y ajustamos nuestras estrategias para asegurar un crecimiento continuo. Exploramos oportunidades para escalar las estrategias exitosas y expandir el alcance de tu negocio, asegurando un retorno sobre la inversión aún mayor."}
              </StepDescription>
            </StepCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default StepGrid;
