import { Box, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import React from 'react';
import Desarrollo from "../../../../assets/Desarrollo.webp";

const articles = [
    {
      title: "marketing digital      12 DE SEPTIEMBRE DE 2024",
      subtitle: "Scamper: qué es y cómo se usa para resolver problemas",
      description:
        "Al aplicar cada uno de estos componentes a un problema o proyecto, puedes descomponerlo en sus elementos fundamentales y reconfigurarlos de maneras",
      image: Desarrollo, 
    },
    {
        title: "marketing digital      12 DE SEPTIEMBRE DE 2024",
        subtitle: "Scamper: qué es y cómo se usa para resolver problemas",
        description:
          "Al aplicar cada uno de estos componentes a un problema o proyecto, puedes descomponerlo en sus elementos fundamentales y reconfigurarlos de maneras", 
        image: Desarrollo,
    },
    {
       title: "marketing digital      12 DE SEPTIEMBRE DE 2024",
       subtitle: "Scamper: qué es y cómo se usa para resolver problemas",
       description:
        "Al aplicar cada uno de estos componentes a un problema o proyecto, puedes descomponerlo en sus elementos fundamentales y reconfigurarlos de maneras",
       image: Desarrollo,
    },
]

const ContentInfocards = () => {
  return (
    <Box sx={{ padding: "40px" }}>
      <Grid container spacing={6}>       
        {articles.map((article, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <Card
              sx={{
                backgroundColor: "#2C2C2C",
                color: "#FFFFFF",
                background: `radial-gradient(circle at bottom right, #7311BB 10%, transparent 40%),
                            radial-gradient(circle at top left, #7311BB 1%, transparent 0%)`,
                borderRadius: "20px",
                height: "100%",
                border: "1px solid #828080",
                margin: "0 auto",
              }}
            >
              <CardMedia
                component="img"
                height="250"
                image={article.image}
                alt={article.title}
                sx={{ objectFit: "cover", }}
              />
              <CardContent sx={{ color: "white" }}>
                <Typography variant="body1" sx={{ color: "#B51AD8", marginBottom: "20px" }}>
                  {article.title}
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: "20px" }}>
                  {article.subtitle}
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "1rem" }}>
                  {article.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ContentInfocards;
