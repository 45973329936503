import React from "react";
import NavGral from "../navbarHomeGral/navbarGral";
import BannerDePaginaPrincipal from "../components/banners/bannerDePaginaPrincipal/bannerPaginaPrincipal"; // Importa el nuevo componente
import ServicesGrid from "../portfolio/components/ServicesGrid";
import Footer from "../footer/page";
import { Box, Grid2 } from "@mui/material";

const Portfolio = () => {
  return (
    <Box
      sx={{
        padding: 0,
        margin: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <Grid2
        container
        sx={{ padding: 0, margin: 0, width: '100%' }}
      >
        <NavGral />

        {/* Envío de props al componente BannerDePaginaPrincipal */}
        <BannerDePaginaPrincipal
          title="CLIENTES QUE TRABAJAN"
          subtitle="O TRABAJARON CON NOSOTROS"
          backgroundGradient="radial-gradient(circle, rgba(115, 17, 187, 0.7), transparent 60%)"
          textColor="#FFFFFF"
        />

        <ServicesGrid />
      </Grid2>
      <Footer />
    </Box>
  );
};

export default Portfolio;
