import React from "react";
import { Box, Card, Typography } from "@mui/material";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#2bd374" : "#2bd374",
  },
}));

const BorderLinearProgress1 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#ff0000" : "#ff0000",
  },
}));

const StayRegister = () => {
  return (
    <Box>
      <Card sx={{ backgroundColor: "#D9D9D9", borderRadius: "12px", padding: "18px",
          width: "330px", height: "105px", display: "flex", alignItems: "center" }} >
        <HourglassEmptyIcon sx={{ fontSize: "35px", color: "#000", marginRight: "16px" }} />
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6"> Registro de estadía </Typography>
          <Box sx={{ marginBottom: "10px" }}>
            <Box display="flex" alignItems="center">
              <BorderLinearProgress variant="determinate" value={50} sx={{ width: "100%" }} />
              <Box display="flex" alignItems="center" sx={{ marginLeft: "8px" }}>
                <ArrowUpwardIcon sx={{ color: "green", fontSize: "16px" }} />
                <Typography variant="body2" sx={{ color: "green", fontSize: "12px", marginLeft: "4px" }}>
                  12%
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box display="flex" alignItems="center">
              <BorderLinearProgress1 variant="determinate" value={30} sx={{ width: "100%" }} />
              <Box display="flex" alignItems="center" sx={{ marginLeft: "8px" }}>
                <ArrowDownwardIcon sx={{ color: "red", fontSize: "16px" }} />
                <Typography variant="body2" sx={{ color: "red", fontSize: "12px", marginLeft: "4px" }}>
                  30%
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default StayRegister;
