import React from "react";
import { Box, Typography, Card, Grid } from "@mui/material";

// Componente reutilizable para el título de la página
const TitlePage = ({ subtitle, title }) => {
  return (
    <Grid container>
      <Box
        sx={{
          color: "#ffffff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: { xs: "10px", sm: "20px", md: "40px", xl: "20px 40px" },
          width: "100%",
        }}
      >
        <Card
          sx={{
            borderRadius: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#ffffff",
            padding: { xs: "20px", sm: "30px", md: "40px" },
            marginBottom: { xs: "50px", md: "50px" },
            width: "97%",
            height: { xs: "130px", lg: "230px" },
            textAlign: "center",
            background: "transparent",
            position: "relative",
            overflow: "hidden",
            border: "1px solid rgba(255, 255, 255, 0.2)",
            "&::before, &::after": {
              content: '""',
              position: "absolute",
              width: "450px",
              height: "450px",
              borderRadius: "50%",
              background: "radial-gradient(circle, rgba(115, 17, 187, 0.7), transparent 60%)",
              transition: "background 0.3s",
            },
            "&::before": {
              top: "10px",
              left: "-180px",
            },
            "&::after": {
              bottom: "10px",
              right: "-180px",
            },
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: "0.8rem", sm: "1.0rem", md: "1.0rem" },
            }}
          >
            {/* Subtítulo reutilizable */}
            {subtitle}
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "2.0rem", sm: "2rem", md: "2.5rem" },
              }}
            >
              {/* Título reutilizable */}
              {title}
            </Typography>
          </Typography>
        </Card>
      </Box>
    </Grid>
  );
};

export default TitlePage;
