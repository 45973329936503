import React from 'react';
import { FONT_COLOR_GRAY } from '../constant/Colors'; 
const Ray = () => {
    return (
        <div
            style={{
                height: 1,
                width: '100%',
                backgroundColor: 'rgb(191,191,191)'
            }}
        />
    );
};

export default Ray;
