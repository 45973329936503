import React from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const articles = [
  {
    title: "Scamper: qué es y cómo se usa para resolver problemas",
    date: "12 DE SEPTIEMBRE DE 2024",
    description:
      "Al aplicar cada uno de estos componentes a un problema o proyecto, puedes descomponerlo en sus elementos fundamentales y reconfigurarlos de maneras novedosas...",
  },
  {
    title: "Scamper: qué es y cómo se usa para resolver problemas",
    date: "12 DE SEPTIEMBRE DE 2024",
    description:
      "Al aplicar cada uno de estos componentes a un problema o proyecto, puedes descomponerlo en sus elementos fundamentales y reconfigurarlos de maneras novedosas...",
  },
  {
    title: "Scamper: qué es y cómo se usa para resolver problemas",
    date: "12 DE SEPTIEMBRE DE 2024",
    description:
      "Al aplicar cada uno de estos componentes a un problema o proyecto, puedes descomponerlo en sus elementos fundamentales y reconfigurarlos de maneras novedosas...",
  },
  {
    title: "Scamper: qué es y cómo se usa para resolver problemas",
    date: "12 DE SEPTIEMBRE DE 2024",
    description:
      "Al aplicar cada uno de estos componentes a un problema o proyecto, puedes descomponerlo en sus elementos fundamentales y reconfigurarlos de maneras novedosas...",
  },
];

const ComercioCards = () => {
  return (
    <Box sx={{ padding: "0px 65px", margin: "0px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "40px",
          marginTop: "60px",
          padding: "0px 30px",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "white",
            textTransform: "uppercase",
            fontWeight: "bold",
            letterSpacing: "2px",
            fontSize: "22px",
          }}
        >
          AREA COMERCIAL
        </Typography>
        <Box
          sx={{
            height: "4px",
            width: "60%",
            backgroundColor: "#B51AD8",
            borderRadius: "25px",
          }}
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="subtitle1"
            sx={{ color: "#fff", fontSize: "14px" }}
          >
            Ver más artículos de comercio
          </Typography>
          <ArrowOutwardIcon sx={{ color: "#fff" }} />
        </Box>
      </Box>
      

      <Grid container spacing={4} sx={{ flexDirection: "row" }}>
      <Grid item xs={12} md={3}>
          <Card
            sx={{
              color: "#FFFFFF",
              background: `radial-gradient(circle at top center, #7311BB 15%, transparent 50%)`,
              borderRadius: "15px",
              border: "1px solid #828080",
              padding: "10px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // Centra el contenido verticalmente
              alignItems: "center", // Centra el contenido horizontalmente
              height: "100%",
              width: "100%",
            }}
          >
            <CardContent
              sx={{
                textAlign: "center", // Alinea el texto al centro horizontalmente
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  textTransform: "uppercase",
                }}
              >
                Plantillas Gratuitas para Crear Customer Journey Maps
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#E0E0E0", marginBottom: "20px" }}
              >
                Utiliza estas plantillas de Customer Journey Map para
                identificar los distintos pasos y necesidades de un cliente
                potencial hasta realizar una compra.
              </Typography>
            </CardContent>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#B51AD8",
                color: "white",
                borderRadius: "30px",
                fontWeight: "bold",
                padding: "10px 20px",
                "&:hover": {
                  backgroundColor: "#9D1BC2",
                },
              }}
            >
              Descargar Ahora
            </Button>
          </Card>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={4}>
            {articles.map((article, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card
                  sx={{
                    color: "#FFFFFF",
                    background: `radial-gradient(circle at bottom right, #7311BB 10%, transparent 40%),
                    radial-gradient(circle at top left, #7311BB 1%, transparent 0%)`,
                    borderRadius: "15px",
                    height: "100%",
                    border: "1px solid #828080",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",
                    padding: "20px",
                    margin: "0px",
                  }}
                >
                  <CardContent>
                    <Typography variant="body2" sx={{ color: "#B51AD8" }}>
                      {article.date}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        margin: "10px 0",
                        textTransform: "uppercase",
                      }}
                    >
                      {article.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#A0A0A0" }}>
                      {article.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>

      </Grid>
    </Box>
  );
};

export default ComercioCards;
