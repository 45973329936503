import React from "react";
import { Card, CardContent, Typography, Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"; // Importa el ícono de flecha

// Estilos del componente usando styled
const StyledCard = styled(Card)({
  width: "100%",
  textAlign: "center",
  color: "#ECEAEF",
  borderRadius: "15px",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "450px",
  marginBottom: "30px",
  background: "transparent",
  "&:hover": {
    transform: "translateY(-5px)", // Cambiado para menos elevación
    "& .hover-button": {
      backgroundColor: "#7311bb", // Cambia el color de fondo del botón
      color: "#ECEAEF", // Cambia el color del texto del botón
      transition: "background-color 0.3s ease, color 0.3s ease", // Añadido para suavidad
    },
    "& .hover-title": {
      color: "#B51AD8", // Cambia el color del título
      transition: "color 0.3s ease", // Añadido para suavidad
    },
  },
});

// Componente ServiceCard
const ServiceCard = ({ title, description, image, link }) => {
  return (
    <StyledCard
      sx={{
        height: { xs: "auto", md: "530px" },
        maxWidth: { xs: "95%", md: "380px" },
      }}
    >
      <CardContent
        sx={{
          backgroundColor: "#7311bb",
          gap: {xs: "30px", md: "30px" },
          padding: "50px 20px",
          width: "100%",
          height: "100%",
          borderRadius: "15px",
          zIndex: 99,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between", // Distribuye espacio entre elementos
          alignItems: "center",
          "&:hover": {
            background: "#ECEAEF",
            color: "#0E0610",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Sombra sutil
            transition:
              "background 0.3s ease, color 0.3s ease, box-shadow 0.3s ease", // Añadido para suavidad
          },
        }}
      >
        {/* Sección de la imagen y título */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={image}
            alt={title}
            style={{
              maxWidth: "80%",
              height: "150px",
              marginBottom: "20px",
              display: "flex",
              zIndex: 100,
              transition: "transform 0.3s ease", // Añadido para suavidad
            }}
          />
          <Typography
            variant="h6"
            className="hover-title"
            sx={{
              width: "95%",
              fontWeight: "bold",
              color: "#ECEAEF",
              transition: "color 0.3s ease", // Añadido para suavidad
            }}
          >
            {title}
          </Typography>
        </Box>

        {/* Descripción */}
        <Typography
          variant="body2"
          sx={{ width: "90%", flexGrow: 1 }}
        >
          {description}
        </Typography>

        {/* Botón pegado abajo */}
        <Button
          component={Link}
          to={link}
          className="hover-button"
          sx={{
            width: "80%",
            marginBottom: "10px",
            padding: "10px 20px",
            backgroundColor: "#ECEAEF", // Color de fondo original
            color: "#B51AD8",
            borderRadius: "25px",
            fontWeight: "bold",
            fontSize: "16px",
            boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
            transition:
              "background-color 0.3s ease, color 0.3s ease, transform 0.3s ease",
            "&:hover": {
              backgroundColor: "#7311bb", // Cambiar a un color de fondo diferente
              color: "#ECEAEF", // Cambiar el color del texto para que contraste con el fondo
              transform: "translateY(-2px)",
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Ver más
          <ArrowForwardIcon
            sx={{
              fontSize: "20px",
              marginLeft: "8px",
              transform: "rotate(320deg)", // Inclinación de la flecha hacia arriba a la derecha
              transition: "color 0.1s ease", // Añadido para suavidad en el color
              color: "inherit", // Hace que siga el color del texto
            }}
          />
        </Button>
      </CardContent>
    </StyledCard>
  );
};

export default ServiceCard;
