import React from 'react';
import { Container, ContentContainer, Item, PrincipalContainer, Wrapper } from '../components/layout/layout';
import LoginForm from './components/Form'; // Asegúrate de que la ruta sea correcta
import loginIllustration from '../../assets/loginIlustration.svg'; // Importa la imagen
import styled from '@emotion/styled';

const ResponsiveImage = styled.img`
    object-fit: fill;
    width: 100%;
    height: 100%;
    display: none;

    @media (min-width: 600px) {
        display: block;
    }
`;

const Login = () => {
    return (
        <Wrapper bg={'rgb(239,239,239)'} ac>
            <PrincipalContainer>
                <ContentContainer>
                    <Container alignItems="center" justifyContent="space-around" gap={2}>
                        <Item xs={12} sm={5}>
                            <LoginForm />
                        </Item>
                        <Item xs={12} sm={6}>
                            <ResponsiveImage 
                                src={loginIllustration} 
                                alt='Login Illustration' 
                            />
                        </Item>
                    </Container>
                </ContentContainer>
            </PrincipalContainer>
        </Wrapper>
    );
}

export default Login;
