import React from "react";
import TypographyColumns from "../../../components/cabeceras/vertical/verticalUno/VerticalUno"; // Ajusta la ruta según la ubicación del archivo
import { Box } from "@mui/material";
import ImagenFraseInspiracion from "./imagenes/imagenes"; // Ajusta la ruta según la ubicación del archivo
import RecogerCorreoLead from "../../../components/contact/recogerCorreoLead/recogerCorreoLead";
import { motion } from "framer-motion"; // Importamos Framer Motion

// Componente que genera los datos para tipografía
const PortadaHome = () => {
  const typographyData = [
    {
      variant: "body1",
      color: "#B51AD8",
      textTransform: "uppercase",
      fontSize: { xs: "16px", sm: "18px", md: "18px", lg: "18px" }, // Ajuste responsivo de tamaño
      maxWidth: "40%",
      text: "UN FUTURO CONECTADO",
      fontWeight: 500,
    },
    {
      variant: "body1",
      textTransform: "uppercase",
      fontSize: { xs: "36px", sm: "42px", md: "52px", lg: "52px" }, // Ajuste responsivo de tamaño
      maxWidth: "85%",
      text: "SOMOS UN ENTORNO ECONÓMICO DE CAPITALIZACIÓN EFECTIVA",
      fontWeight: "bold",
      lineHeight: {
        xs: "45px",   // para pantallas extra pequeñas
        sm: "50px",   // para pantallas pequeñas
        md: "58px",   // para pantallas medianas (el más grande)
        lg: "58px",   // para pantallas grandes
        xl: "58px",   // para pantallas extra grandes
      },
    },
    {
      variant: "body2",
      fontSize: { xs: "14px", sm: "14px", md: "16px", lg: "16px" }, // Ajuste responsivo de tamaño
      maxWidth: { xs: "70%", md: "45%" },
      text: "Nos esforzamos cada día para seguir ayudando a que profesionales, emprendedores, empresarios e inversores cuenten con el único lugar centralizado de capitalización efectivo y seguro, donde puedan conseguir todo lo que necesitan para capitalizar a través de internet.",
      fontWeight: "light",
    },
  ];

  return (
    <Box>
      {/* Animar texto */}
      <motion.div
        initial={{ opacity: 0, y: 50 }} // Estado inicial
        animate={{ opacity: 1, y: 0 }} // Estado cuando se anima
        transition={{ duration: 1 }} // Duración de la animación
      >
        <TypographyColumns data={typographyData} />
      </motion.div>

      {/* Animar formulario de recolección de correo */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.5 }} // Animación con retraso
      >
        <RecogerCorreoLead />
      </motion.div>

      {/* Animar imagen */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 1 }} // Retraso para que aparezca después
      >
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            padding: { xs: "0px 15px", md: "0px 60px" },
            position: "relative",
            bottom: { md: "100px" },
            marginTop: "50px",
          }}
        >
          <ImagenFraseInspiracion />
        </Box>
      </motion.div>
    </Box>
  );
};

export default PortadaHome;
