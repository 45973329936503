import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom"; // Si estás usando react-router-dom

const CajaDeBotones = ({ title, buttonTexts, buttonLinks }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
      }}
    >
      <Box 
        sx={{
          borderRadius: '25px', 
          backgroundColor: "#7311bb", 
          width: "100%", 
          padding: "10px",
          boxShadow: 2, // Añadir sombra para un efecto visual
        }}
      >
        <Typography
          variant="h5"
          sx={{
            margin: 1,
            textAlign: "center",
            fontFamily: "Poppins, sans-serif",
            fontSize: { xs: "16px", sm: "18px" }, // Tamaño de fuente responsivo
            fontWeight: 500,
            color: "#ECEAEF",
          }}
        >
          {title}
        </Typography>

        <Box
          sx={{
            gap: 1,
            display: "flex",
            width: "100%",
            flexDirection: 'column', // Cambiar dirección en pantallas grandes
            padding: "10px 30px",
          }}
        >
          {buttonTexts.map((text, index) => (
            <Button
              key={index}
              component={Link} // Usar 'Link' de react-router-dom para navegación interna
              to={buttonLinks[index]} // Asignar el enlace correspondiente
              variant="contained"
              sx={{
                backgroundColor: "#ECEAEF",
                color: "#0E0610",
                borderRadius: "25px",
                "&:hover": {
                  backgroundColor: "#B51AD8", // Cambiar el fondo a color morado en hover
                  color: "white", // Cambiar el color del texto a blanco
                },
                margin: { xs: '5px 0', sm: '0 5px' } // Margen responsivo
              }}
            >
              {text}
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CajaDeBotones;
