import React from "react";
import NavGral from "../navbarHomeGral/navbarGral";
import HomeBlogs from "../blog/components/HomeBlogs";
import FeaturedArticles from "../blog/components/FeaturedArticles";
import InfoCards from "../blog/components/InfoCards";
import Footer from "../footer/page";
import MarketingCards from "./components/MarketingCards";
import ComercioCards from "./components/ComercioCards";
import TecnologiaCards from "./components/TecnologiaCards";
import { Box } from "@mui/material";

const Blog = () => {
  return (
    <Box>
      <NavGral />
      <HomeBlogs />
      <Box sx={{padding: {xs: "0px", md:"0px 25px"}}}>
        <FeaturedArticles />
        <InfoCards />
      </Box>
      <MarketingCards />
      <ComercioCards />
      <TecnologiaCards />

      <Footer />
    </Box>
  );
};

export default Blog;
