import React from "react";
import { Card, Box, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Line } from "react-chartjs-2";
import { styled } from "@mui/material/styles";
import { Chart as ChartJS, LineElement, PointElement,LinearScale,
  CategoryScale, Filler, Tooltip,} from "chart.js";

ChartJS.register( LineElement, PointElement, LinearScale,CategoryScale,
  Filler, Tooltip );

const data = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
  datasets: [
    {
      data: [65, 59, 80, 81, 56, 55, 90],
      fill: true,
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "rgba(75,192,192,1)",
      tension: 0.4,
    },
  ],
};

const options = {
  scales: {
    x: {
      display:  2,
    },
    y: {
      display: 12,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

const CustomCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#F5F5F5",
  borderRadius: "20px",
  padding: "25px",
  width: "239px",
  height: "69px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const StatusCard = () => {
  return (
    
      <Box display="flex" justifyContent="center" alignItems="center">
        <CustomCard>
          <Box sx={{ flexGrow: 1 }}>
            <Box  display="flex"
              justifyContent="space-between"
              alignItems="center">
              <Typography variant="body2"> Junio, 2024 </Typography>
              <Box display="flex" alignItems="center">
                <ArrowUpwardIcon sx={{ color: "green", fontSize: "16px" }} />
                <Typography variant="body2" sx={{ color: "blue", fontSize: "12px", marginLeft: "4px" }} >
                  78%
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
            <Typography variant="h6"sx={{ fontWeight: "bold", fontSize: "14px", marginBottom: "4px"}}            >
              Estatus
            </Typography>
            <Box sx={{ height: "40px" }}>
              <Line data={data} options={options} />
            </Box>
            </Box>
          </Box>
        </CustomCard>
      </Box>
  );
};

export default StatusCard;
