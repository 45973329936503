import React from 'react';
import { Button, Typography } from '@mui/material';
import facebookLogo from '../../../assets/facebook.png'; // Ajusta la ruta según la ubicación real de tu imagen

const FacebookButton = () => {
    return (
        <Button
            style={{
                backgroundColor: 'rgb(239,239,239)',
                width: 140,
                height: 56,
                alignItems: 'center',
                display: 'flex',
                gap: 8,
                justifyContent: 'center',
                borderRadius: 8
            }}
        >
            <img src={facebookLogo} alt='Facebook logo' style={{ width: 24, height: 24 }} />
            <Typography
                style={{
                    marginTop: 4,
                    fontSize: 15,
                    fontWeight: 700,
                    textTransform: 'none',
                    color: 'black'
                }}
            >
                Facebook
            </Typography>
        </Button>
    );
};

export default FacebookButton;
