import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const FAQSection = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        padding: "50px 20px",
        borderRadius: "10px",
      }}
    >
      <Box textAlign="center" sx={{ mb: 5 }}>
        <Typography
          variant="h6"
          sx={{ color: "#B51AD8", mb: 2, fontWeight: "bold" }}
        >
          {" "}
          ALGUNAS RESEÑAS{" "}
        </Typography>
        <Typography
          variant="h3"
          sx={{
            color: "#fff",
            fontWeight: "bold",
            fontSize: {
              xs: "30px",
              sm: "2.5rem",
              md: "3rem",
              lg: "3.25rem",
            },
          }}
        >
          {" "}
          ¿TIENES PREGUNTAS? <br /> ¡TENEMOS RESPUESTAS!
        </Typography>
      </Box>

      <Box>
        {/* Primer Acordeón */}
        <Accordion
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            color: "#fff",
            mb: 2,
            borderRadius: "10px",
            border: "1px solid rgba(255, 255, 255, 0.2)",
          }}
        >
          <AccordionSummary
            expandIcon={
              <AddIcon
                sx={{
                  color: "#ffffff",
                  backgroundColor: "#28122d",
                  borderRadius: "20%",
                  padding: "0px",
                }}
              />
            }
          >
            <Typography>
              ¿Cómo puede Hitpoly transformar la manera en que capitalizas en
              internet y expandes tu negocio?{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Hitpoly es un entorno diseñado para conectar empresas, freelancers
              y comercializadores. Ofrecemos soluciones tecnológicas y de
              marketing que ayudan a marcas y empresas a capitalizar en
              internet. Además, creamos una comunidad donde los freelancers
              pueden colaborar en proyectos y generar ingresos, mientras los
              comercializadores traen clientes interesados en capitalizar de
              forma efectiva y segura su presencia digital. Hitpoly impulsa la
              reactivación económica en Latinoamérica y el mundo a través de
              tecnología innovadora y segura.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/*Segundo Acordeón */}

        <Accordion
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            color: "#fff",
            mb: 2,
            borderRadius: "10px",
            border: "1px solid rgba(255, 255, 255, 0.2)",
          }}
        >
          <AccordionSummary
            expandIcon={
              <AddIcon
                sx={{
                  color: "#ffffff",
                  backgroundColor: "#28122d",
                  borderRadius: "20%",
                  padding: "0px",
                }}
              />
            }
          >
            <Typography>
              ¿Cómo puedo colaborar como freelancer en Hitpoly?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Hitpoly te permite unirte a nuestra comunidad global de
              freelancers. Después de unirte, tendrás acceso a proyectos en
              áreas como marketing digital, diseño web, desarrollo de software,
              y más. Trabajamos con empresas de todo Latinoamerica,
              permitiéndote colaborar de forma remota en proyectos que se
              alineen con tus habilidades, generando ingresos constantes.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Tercer Acordeón */}

        <Accordion
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            color: "#fff",
            mb: 2,
            borderRadius: "10px",
            border: "1px solid rgba(255, 255, 255, 0.2)",
          }}
        >
          <AccordionSummary
            expandIcon={
              <AddIcon
                sx={{
                  color: "#ffffff",
                  backgroundColor: "#28122d",
                  borderRadius: "20%",
                  padding: "0px",
                }}
              />
            }
          >
            <Typography>
              ¿Cómo puedo ser comercializador y traer clientes a Hitpoly?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Si eres comercializador, puedes unirte a nuestro programa para
              generar ingresos ayudando a conectar empresas con nuestras
              soluciones. Al identificar clientes potenciales que necesitan
              optimizar sus procesos de marketing o comunicación, te conviertes
              en un puente entre ellos y nuestra comunidad de expertos,
              obteniendo comisiones por cada proyecto concretado.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Cuarto Acordeón */}

        <Accordion
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            color: "#fff",
            mb: 2,
            borderRadius: "10px",
            border: "1px solid rgba(255, 255, 255, 0.2)",
          }}
        >
          <AccordionSummary
            expandIcon={
              <AddIcon
                sx={{
                  color: "#ffffff",
                  backgroundColor: "#28122d",
                  borderRadius: "20%",
                  padding: "0px",
                }}
              />
            }
          >
            <Typography>
              ¿Es seguro colaborar y trabajar a través de Hitpoly?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Sí, garantizamos un entorno seguro para todas las transacciones y
              colaboraciones. Utilizamos protocolos de seguridad avanzados para
              proteger tanto a las empresas como a los freelancers, ofreciendo
              sistemas de pago seguros y comunicación transparente. Hitpoly es
              un entorno seguro donde todos los involucrados cumplen con los
              estándares de calidad, generando confianza y resultados efectivos.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
};

export default FAQSection;
