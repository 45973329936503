import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonPrimary } from '../../components/UI/button/button';
import Input from '../../components/UI/input';
import { Card, Container, Item } from '../../components/layout/layout';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import GoogleButton from '../../components/google/Google';
import FacebookButton from '../../components/facebook/facebook';
import { Typography, Modal, Box } from '@mui/material';
import { FONT_COLOR_GRAY } from '../../components/constant/Colors';
import Ray from '../../components/UI/Ray';
import { Link, useNavigate } from 'react-router-dom';
import { signin } from '../../../reducers/user/userSlice';

const fontFamily = 'Inter';

const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Correo electrónico inválido').required('Campo requerido'),
    password: Yup.string().required('Campo requerido'),
});

const Title = styled.p({
    fontSize: 38,
    fontFamily: fontFamily,
    fontWeight: 600,
    color: 'black',
    textAlign: 'center',
});

const SubTitle = styled.p({
    fontSize: 18,
    fontFamily: fontFamily,
    fontWeight: 400,
    textAlign: 'center',
});

const TextGray = styled(Typography)({
    fontSize: 18,
    fontFamily: fontFamily,
    fontWeight: 400,
    color: FONT_COLOR_GRAY,
});

const TextGrayBold = styled(Typography)({
    fontSize: 18,
    fontFamily: fontFamily,
    fontWeight: 700,
    color: FONT_COLOR_GRAY,
});

const LoginForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, error, isAuthenticated, rol } = useSelector((state) => state.user);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    useEffect(() => {
        if (error) {
            setModalMessage(typeof error === 'string' ? error : 'El correo electrónico o la contraseña son incorrectos.');
            setShowModal(true);
        }
    }, [error]);

    useEffect(() => {
        if (isAuthenticated) {
            if (rol === 'ADMINISTRATOR') {
                navigate('/dashboard');
            } else if (rol === 'USER') {
                navigate('/home');
            }
        }
    }, [isAuthenticated, rol, navigate]);

    const handleSubmit = (values) => {
        dispatch(signin(values));
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <Card>
            <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={LoginSchema}
                onSubmit={handleSubmit}
            >
                {({ errors, touched, isSubmitting }) => (
                    <Form>
                        <Container justifyContent="center">
                            <Item xs={12}>
                                <Title>
                                    ¡Hola! Nos alegra tenerte de vuelta.
                                </Title>
                                <SubTitle>
                                    Lorem ipsum dolor sit amet
                                </SubTitle>
                                <br />
                            </Item>
                            <Item xs={12}>
                                <div>
                                    <Input
                                        label="Correo electrónico:"
                                        type="email"
                                        name="email"
                                        error={errors.email}
                                        touched={touched.email}
                                    />
                                </div>
                                <div>
                                    <Input
                                        label="Contraseña"
                                        type="password"
                                        name="password"
                                        error={errors.password}
                                        touched={touched.password}
                                    />
                                </div>
                                <br />
                                <ButtonPrimary type='submit' disabled={isSubmitting || loading}>
                                    {loading ? 'Cargando...' : 'Log In'}
                                </ButtonPrimary>
                                <br /><br />
                                <div className="flex justify-center">
    <TextGray style={{ display: 'inline', marginRight: '20px' }}>
        ¿No tienes cuenta?
    </TextGray>

    <Link to="/register" style={{ display: 'inline' }}>
        <TextGrayBold style={{ display: 'inline', marginLeft: '100px' }}>
            Crear cuenta
        </TextGrayBold>
    </Link>
</div>
                                <br />
                                <Container justifyContent="center" alignItems="center">
                                    <Item xs={2}>
                                        <Ray />
                                    </Item>
                                    <Item xs={8}>
                                        <div className="text-center">
                                            <TextGray>
                                                Puede iniciar sesión con
                                            </TextGray>
                                        </div>
                                    </Item>
                                    <Item xs={2}>
                                        <Ray />
                                    </Item>
                                </Container>
                                <br />
                                <Container gap={2} justifyContent="center" alignItems="center">
                                    <Item xs={4}>
                                        <GoogleButton />
                                    </Item>
                                    <Item xs={4}>
                                        <FacebookButton />
                                    </Item>
                                    <Item xs={12}>
                                        <div className="text-center">
                                            <TextGray>
                                                support@hitpoly.com
                                            </TextGray>
                                        </div>
                                    </Item>
                                </Container>
                            </Item>
                        </Container>
                    </Form>
                )}
            </Formik>
            <Modal
                open={showModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...modalStyle }}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Error de autenticación
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        {modalMessage}
                    </Typography>
                </Box>
            </Modal>
        </Card>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default LoginForm;
