import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Card, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const SiteVisits = () => {
  return (
    <Box>
      <Card sx={{ borderRadius: "12px",backgroundColor: "#D9D9D9",  padding: 1 ,
          width: "330px", height: "110px", display: "flex", alignItems: "center"}}>
        <Box sx={{ marginLeft: "20px" }}>
          <Typography variant="h6">Número de visitas del sitio</Typography>
          <Box display="flex" alignItems="center">
            <VisibilityIcon sx={{ fontSize: "40px" }} />
            <Typography variant="h3"  sx={{ marginLeft: "20px" }}>2,300</Typography>

            <Box ml={1}>
              <Typography variant="body2">28</Typography>
              <br />
              <Link to="/register">
                <Typography variant="body2" style={{ color: "black" }}>
                  Ver Detalles
                </Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default SiteVisits;
