import React from "react";
import { Card, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import PublicTwoToneIcon from "@mui/icons-material/PublicTwoTone";

const data = [
    { country: "EE.UU", users: 250, percentage: 18, color: "#2bd374", position: { top: "20%", left: "15%" } },
    { country: "Rusia", users: 260, percentage: 20, color: "#0074D9", position: { top: "10%", right: "10%" } },
    { country: "España", users: 120, percentage: 8, color: "#FFDC00", position: { top: "50%", left: "30%" } },
    { country: "Marruecos", users: 130, percentage: 10, color: "#FF4136", position: { bottom: "20%", left: "25%" } },
  ];

  const BackgroundImage = styled(Box)(({ theme }) => ({
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "320px",
    position: "relative",
    borderRadius: "12px",
  }));
  
  const DataBox = styled(Box)(({ position }) => ({
    position: "absolute",
    ...position,
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: "8px",
    padding: "8px 12px",
  }));
  
  const Circle = styled(Box)(({ theme, color }) => ({
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: color,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(1),
  }));


const VisitorsIndex = () => {
    return (
        <Card
          sx={{
            backgroundColor: "#D9D9D9",
            borderRadius: "12px",
            padding: 2,
            height: "500px",
            maxWidth: "400px",
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 2 }}>
            <PublicTwoToneIcon sx={{ mr: 1 }} /> Índice de visitantes por región
          </Typography>
    
          <Typography variant="body2" sx={{ mb: 2 }}>
            Estatus junio, 2024
          </Typography>
    
          <BackgroundImage>
            {data.map((item, index) => (
              <DataBox key={index} position={item.position}>
                <Circle color={item.color}>
                  <Typography variant="body2" sx={{ color: "#fff" }}>
                    {item.percentage}%
                  </Typography>
                </Circle>
                <Box>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {item.country}
                  </Typography>
                  <Typography variant="body2">{item.users} Usuarios</Typography>
                </Box>
              </DataBox>
            ))}
          </BackgroundImage>
        </Card>
      );
    };

export default VisitorsIndex;